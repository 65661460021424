export const PublicRoutes = {
  AUTHSSO: 'auth',
  CREATEPASSWORD: '/password',
  FORGOTPASSWORD: '/forgot-password',
  LOGIN: '/login',
  VERSIONHISTORY: '/version-history',
};
export const PrivateRoute = {
  CANDIDATE: '/admin/candidate',
  CUSTOMER: '/admin/customer',
  DASHBOARD: '/admin/dashboard',
  IPWHITELIST: '/admin/ipwhitelist',
  JOBPOST: '/admin/job-post',
  MARKETER: '/admin/marketer',
  MYPROFILE: '/admin/profile',
  RECRUITER: '/admin/recruiter',
  SALES: '/admin/sales',
  SKILLS: '/admin/skills',
  VERSIONHISTORY: '/version-history',
  VERSIONHISTORYADD: '/version-history/add',
  VIEWJOB: '/admin/view-job/:jobtab/:jobId',
};
export const RecruiterRoute = {
  CANDIDATES: '/recruiter/candidates',
  DASHBOARD: '/recruiter/dashboard',
  JOBPOST: '/recruiter',
  MYPROFILE: '/recruiter/profile',
  RESUMEVALIDATION: '/recruiter/resume-validation',
  VERSIONHISTORY: '/version-history',
  VIEWJOB: '/recruiter/job-post/view-job/:jobtab/:jobId',
};
export const MarketerRoute = {
  CANDIDATES: '/marketer/candidates',
  JOBPOST: '/marketer',
  MYPROFILE: '/marketer/profile',
  VERSIONHISTORY: '/version-history',
  VIEWJOB: '/marketer/job-post/view-job/:jobtab/:jobId',
};

export const SalesRoute = {
  CANDIDATES: '/sales/candidates',
  JOBPOST: '/sales',
  MYPROFILE: '/sales/profile',
  RESUMEVALIDATION: '/sales/resume-validation',
  VERSIONHISTORY: '/version-history',
  VIEWJOB: '/sales/job-post/view-job/:jobtab/:jobId',
};

export const ViewJobConstants = {
  Resumes: 'resumes',
  myPosting: 'my-posting',
};
