import { Loader } from 'components/loader';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ssoLogin } from 'store/actions/UserAction';
import { useAppDispatch } from 'store/hooks';
import { getRequest } from 'utils/Handlers';

const Authsso: React.FC = () => {
  const apiUrl = process.env.REACT_APP_APIURL;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [, setIPAddress] = useState('');

  const loginAPi = useCallback(
    async (token: string) => {
      const ip: string = await new Promise((resolve: any) => {
        setIPAddress((pre) => resolve(pre));
      });
      dispatch(ssoLogin(token, ip));
    },
    [dispatch],
  );

  // const verifySession = useCallback(
  //   async (token: string) => {
  //     try {
  //       await getRequest({
  //         ssoLogin: true,
  //         url: `${apiUrl}auth/sub-domain-verify`,
  //       });
  //       loginAPi(token);
  //     } catch (error) {
  //       if (error?.error?.data?.message === 'No Access To User') {
  //         dispatch(updateNotAuthorized(true));
  //       }
  //     }
  //   },
  //   [dispatch, loginAPi, apiUrl],
  // );
  const setCookies = useCallback(
    async (token: string) => {
      try {
        await getRequest({
          ssoLogin: true,
          url: `${apiUrl}auth/set-cookies?cookieToken=${token}&applicationId=${2}`,
        });
        // verifySession(token);
        loginAPi(token);
      } catch (error) {
        console.warn(error);
      }
    },
    [apiUrl, loginAPi],
  );

  const getIPData = async () => {
    return new Promise((resolve, reject) => {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((data) => {
          setIPAddress(data.ip);
          resolve(data.ip);
        })
        .catch((error) => reject(error));
    });
  };

  const authValidation = async () => {
    await getIPData();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('crfToken');
    if (!token) {
      navigate('/login');
    } else {
      setCookies(token);
    }
  };
  useEffect(() => {
    authValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Loader />;
};

export default Authsso;
