import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { yupResolver } from '@hookform/resolvers/yup';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
// import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
// import StarIcon from '@mui/icons-material/Star';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Drawer,
  Fade,
  IconButton,
  Modal,
  Popper,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { DialogProps } from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import FilterIcon from 'assets/images/bluefilter.svg';
import CloseJob from 'assets/images/closejob.png';
import BackIcon from 'assets/images/larrow.svg';
import CheckIcon from 'assets/images/lightgreencheck.svg';
import StarActiveIcon from 'assets/images/star-active.svg';
import StarInActiveIcon from 'assets/images/star-inactive.svg';
import CommonUploadBtn from 'components/CommonUploadBtn';
import CustomInput from 'components/CustomInput/customInput';
import CustomMultiSelect from 'components/CustomMultiSelect/customMultiSelect';
import CustomSelect from 'components/CustomSelect/customSelect';
import CustomSelectSeach from 'components/CustomSelectSearch';
import UpArrowSvgIcon from 'components/SvgAsCode/upArrow';
import { ViewJobConstants } from 'config';
import { saveAs } from 'file-saver';
import _, { capitalize } from 'lodash';
import moment from 'moment';
import AddAndEditJob from 'pages/common/AddAndEditJob';
import useStyles from 'pages/private/PrivateStyle';
import React, { useEffect, useState } from 'react';
// import FileViewer from 'react-file-viewer';
import { Controller, useForm } from 'react-hook-form';
import LoadingOverlay from 'react-loading-overlay';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { candidateJobFilterData } from 'store/reducers/UserReducer';
import {
  // USER_CANDIDATE_JOB_FILTER_DATA,
  USER_CUSTOMER_DATA,
  USER_RELOAD,
} from 'store/types/UserTypes';
import { AddNotes } from 'types';
import { JOBCLOSINGSTATUS, timeSince } from 'utils/ArrayConstants';
import { alertOptions } from 'utils/Config';
import { MASTER_ENDPOINT, RESUME_ENDPOINT } from 'utils/Constant';
// import Storage from 'utils/Storage';
import { addNoteSchema } from 'utils/ValidatorSchema';

import { PdfViewer } from '../ResumeReview/viewer';
import UploadResumeModal from '../UploadResumeModal';

const stylenote = {
  bgcolor: 'background.paper',
  boxShadow: '0 2px 10px 0 rgba(0,0,0,0.08)',
  left: '50%',
  minHeight: 200,
  padding: '51px 54px 40px 54px',
  position: 'absolute' as const,
  top: '200px',
  transform: 'translate(-50%, -60px)',
  width: 500,
};

const style = {
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  flexDirection: 'column',
  left: '50%',
  p: 4,
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 780,
};

const fileStyle = {
  bgcolor: 'background.paper',
  height: '90vh',
  left: '50%',
  overflowY: 'auto',
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
};

const resumeStyle = {
  bgcolor: 'background.paper',
  height: '90vh',
  left: '50%',
  overflowY: 'auto',
  position: 'absolute' as const,
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
};

const perPAge = 10;

const staticParam = {
  order: 'asc',
  primarySkill: '',
  reload: true,
  skillName: '',
  skip: 0,
  sliceIndex: 0,
  sortby: '',
  take: perPAge,
};

export const JobView: React.FC<any> = () => {
  const classes = useStyles();
  const { jobId, jobtab } = useParams();
  const dispatch: any = useDispatch();
  const navigation: any = useNavigate();
  const history = useNavigate();
  const [JobListingData, setJobListingData] = useState<any>([
    { label: 'Select a source for the resume', value: undefined },
  ]);
  const { userInfo: userDetail }: any = useAppSelector(
    (store: any) => store.userLogin,
  );
  const [opening, setopening] = useState<boolean>(false);
  const [opend, setOpend] = useState<boolean>(false);
  const [jobData, setJobData] = useState<any>({});
  const [param, setParam] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);
  // const [searchclear, setsearchclear] = useState<boolean>(false);
  const [matchedList, setMatchedList] = useState<any>([]);
  // const [matchedListOverAll, setMatchedListOverAll] = useState<any>([]);
  const [matchedTotal, setMatchedTotal] = useState<number>(0);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageScale, setPageScale] = useState(1);
  const [tabSearch, setTabSearch] = React.useState<string>('1');
  function CloseFn() {
    setopening((p) => !p);
    setSuccessUPRESLoader(false);
  }
  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const handleZoomIn = () => {
    if (pageScale < 3) {
      setPageScale(pageScale + 0.2);
    }
  };

  const handleZoomOut = () => {
    if (pageScale > 0.3) {
      setPageScale(pageScale - 0.2);
    }
  };

  const handleNext = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const handlePrevious = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const [addOpen, setAddOpen] = useState(false);
  const handleAddOpen = () => setAddOpen(true);
  const handleAddClose = () => setAddOpen(false);
  const handleOpendelete = () => {
    setOpend(true);
  };
  const handleClosedelete = () => setOpend(false);
  const location = useLocation();
  const [candidateJobFilter, setCandidateJobFilter] = useState(
    candidateJobFilterData,
  );
  const { reload, userInfo, customerInfo }: any = useAppSelector(
    (store: any) => store.userLogin,
  );
  // const TokenStatus: any = Storage.getTokens();
  // let token = TokenStatus && TokenStatus.token ? TokenStatus.token : '';
  // if (token) {
  //   token = 'Bearer '.concat(token);
  // }
  const { control, formState, handleSubmit, reset } = useForm<AddNotes>({
    defaultValues: {
      notes: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(addNoteSchema),
  });

  const resetForm = () => {
    reset();
    setAddOpen(false);
  };

  const submitForm = (data: any) => {
    const AddNoteobj = {
      candidateId: viewRow.id,
      notes: data.notes,
    };
    addNewData(
      AddNoteobj,
      MASTER_ENDPOINT.Candidates + '/notes',
      {},
      true,
    ).then((resp: any) => {
      getNotes(viewRow);
      setAddOpen(false);
      resetForm();
      setParam({
        ...param,
        reload: !param.reload,
      });
    });
  };

  const [companyList, setCompanyList] = useState([]);

  const getCompanyList = () => {
    getAllListData(
      MASTER_ENDPOINT.Organization + '?order=asc&sortby=name',
    ).then((resp: any) => {
      setCompanyList(resp?.data);
    });
  };

  const [workStatus, setWorkStatus] = React.useState<any>([]);
  const getWorkStatus = () => {
    getAllListData(MASTER_ENDPOINT.WorkStatus + '?status=true').then(
      (resp: any) => {
        setWorkStatus(resp?.data);
      },
    );
  };
  async function ListApiCall() {
    setResumeuploadLoader(true);
    try {
      const resp = await getAllListData(
        `${RESUME_ENDPOINT.ResumeValidationsources}`,
      );

      if (resp && resp.data) {
        setJobListingData(resp.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setResumeuploadLoader(false);
    }
  }
  useEffect(() => {
    ListApiCall();
    getWorkStatus();
    getSkillAndCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSkillAndCustomer = () => {
    getAllListData(
      MASTER_ENDPOINT.Customer + '?status=true&order=asc&sortby=name',
    ).then((resp: any) => {
      if (!_.isEqual(customerInfo, resp?.data)) {
        dispatch({
          payload: resp.data,
          type: USER_CUSTOMER_DATA,
        });
      }
    });
  };

  useEffect(() => {
    getJobData(jobId);
    getCompanyList();
  }, [jobId, reload]);

  const [loading, setLoading] = React.useState<boolean>(false);

  const sendMailToAll = (event: any) => {
    setLoader(true);
    const url = `${MASTER_ENDPOINT.Candidates}/bulk-mail?skillNames=${String(
      param.skillName,
    )}&jobId=${jobId}`;
    getAllListData(url)
      .then((resp) => {
        setLoader(false);
        if (resp?.data.length > 0) {
          const allEmail = [];
          resp.data.map((allContact) => {
            allEmail.push(allContact.email);
          });
          if (allEmail.length > 0) {
            window.location.href = `mailto:?bcc=${String(allEmail.join('; '))}`;
            event.preventDefault();
          } else {
            toast.error('Choose atleast one candidate as favourite');
          }
        } else {
          toast.error('Choose atleast one candidate as favourite');
        }
      })
      .catch(() => {
        setLoading(false);
        setLoader(false);
      });
  };

  const [openfilter, setOpenFilter] = React.useState(false);

  const applyFilter = () => {
    // if (tabSearch === '2') {
    //   if (candidateJobFilter?.booleanSearch) {
    //     const searchAnd = candidateJobFilter?.booleanSearch
    //       .toLocaleLowerCase()
    //       .search(' and ');
    //     const searchOr = candidateJobFilter?.booleanSearch
    //       .toLocaleLowerCase()
    //       .search(' or ');
    //     if (searchAnd === -1 && searchOr === -1) {
    //       toast.error('Enter a search with boolean condition');
    //       setLoading(false);
    //       return true;
    //     }
    //   }
    //   if (candidateJobFilter?.title) {
    //     const searchAndTitle = candidateJobFilter?.title
    //       .toLocaleLowerCase()
    //       .search(' and ');
    //     const searchOrTitle = candidateJobFilter?.title
    //       .toLocaleLowerCase()
    //       .search(' or ');
    //     if (searchAndTitle === -1 && searchOrTitle === -1) {
    //       toast.error('Enter a search with boolean condition');
    //       setLoading(false);
    //       return true;
    //     }
    //   }
    // }
    if (!_.isEqual(candidateJobFilterData, candidateJobFilter)) {
      filterEdit('applyFilter', true);
      setParam({ ...param, reload: !param.reload, skip: 0, sliceIndex: 0 });
      setPage(1);
    }
    setOpenFilter(false);
  };

  const filterEdit = (key: string, val: any) => {
    setCandidateJobFilter({
      ...candidateJobFilter,
      [key]: val,
    });
    // dispatch({
    //   payload: {
    //     ...candidateJobFilter,
    //     [key]: val,
    //   },
    //   type: USER_CANDIDATE_JOB_FILTER_DATA,
    // });
  };

  const resetFilter = () => {
    if (!_.isEqual(candidateJobFilterData, candidateJobFilter)) {
      setParam({ ...param, reload: !param.reload, skip: 0, sliceIndex: 0 });
      setPage(1);
      // dispatch({
      //   payload: { ...candidateJobFilterData },
      //   type: USER_CANDIDATE_JOB_FILTER_DATA,
      // });
      setCandidateJobFilter({
        ...candidateJobFilterData,
      });
    }
    setTabSearch('1');
    setOpenFilter(false);
  };

  const getCandidateData = React.useCallback(() => {
    setLoading(true);
    setMatchedTotal(0);
    let url = `${MASTER_ENDPOINT.MatchedCandidate}?order=${String(
      param.order,
    )}&sortby=${String(param.sortby)}&jobId=${jobId}&skillNames=${String(
      encodeURIComponent(param.skillName),
    )}&primarySkills=${String(
      encodeURIComponent(param.primarySkill),
    )}&skip=${String(param.skip)}&take=10`;
    // {String(param.take)}

    if (candidateJobFilter?.applyFilter) {
      let skillId = '';
      if (
        candidateJobFilter?.skillData &&
        candidateJobFilter?.skillData?.length > 0
      ) {
        candidateJobFilter?.skillData.map((item: any) => {
          skillId += item?.name + ' and ';
        });
      }
      if (skillId) {
        skillId = skillId.replace(/ and \s*$/, '');
      }
      url += `&location=${String(
        candidateJobFilter?.location ? candidateJobFilter?.location : '',
      )}&${tabSearch === '1' ? 'jobTitle' : 'jobBoolean'}=${String(
        encodeURIComponent(candidateJobFilter?.title)
          ? encodeURIComponent(candidateJobFilter?.title)
          : '',
      )}&workAuth=${String(
        candidateJobFilter?.workAuth
          ? candidateJobFilter?.workAuth.toString()
          : '',
      )}&skillId=${
        tabSearch === '1' && skillId ? encodeURIComponent(skillId) : ''
      }&organizationId=${String(
        candidateJobFilter?.organizationId
          ? candidateJobFilter?.organizationId
          : '',
      )}`;
      if (candidateJobFilter?.booleanSearch) {
        url += `&boolean=${String(
          encodeURIComponent(candidateJobFilter?.booleanSearch),
        )}`;
      }
      url += '&isApply=true';
    }

    // getAllListData(url + '&type=total')
    //   .then((respTotal: any) => {
    //     setMatchedTotal(respTotal.total);
    //   })
    //   .catch(() => {
    //     setMatchedTotal(0);
    //   });

    getAllListData(url + '&type=data')
      .then((resp) => {
        setMatchedList(
          resp?.data.slice(param.sliceIndex, param.sliceIndex + perPAge),
        );
        // setMatchedListOverAll(resp?.data);
        setMatchedTotal(resp?.total);
      })
      .catch(() => {
        setMatchedList([]);
        // setMatchedListOverAll([]);
        setMatchedTotal(0);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [param]);

  const getJobData = async (id: string) => {
    setLoader(true);
    await getAllListData(`${MASTER_ENDPOINT.Jobs}?id=${id}`)
      .then((res) => {
        setJobData(res?.data?.[0]);
        if (res?.data?.[0]?.jobSkills) {
          let sName = '';
          let primarySkill = '';
          res?.data?.[0]?.jobSkills.map((item: any) => {
            sName += item.skill.name + ',';
            primarySkill += item.skill.primary ? item.skill.name + ',' : '';
          });
          if (sName) {
            sName = sName.replace(/,\s*$/, '');
          }
          if (primarySkill) {
            primarySkill = primarySkill.replace(/,\s*$/, '');
          }

          setParam({
            ...staticParam,
            primarySkill,
            skillName: sName,
          });
        }
      })
      .catch((err: any) => {})
      .finally(() => {
        setLoader(false);
      });
  };

  const goBackFun = () => {
    navigation(-1);
  };

  const [open1, setOpen1] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
    setOpen1(true);
    setScroll(scrollType);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  const [page, setPage] = useState<number>(1);
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setMatchedList([]);
    setParam({
      ...param,
      skip: (value - 1) * perPAge,
    });
    setPage(value);
    // const remainder = (value - 1) % 5;
    // const values = [0, 10, 20, 30, 40];
    // if (
    //   Math.ceil((param.skip / perPAge + 1) / 5) * 5 ===
    //   Math.ceil(value / 5) * 5
    // ) {
    //   setMatchedList(
    //     matchedListOverAll.slice(
    //       Number(values[remainder]),
    //       Number(values[remainder]) + perPAge,
    //     ),
    //   );
    //   setPage(value);
    // } else {
    //   setParam({
    //     ...param,
    //     skip: (Math.ceil(value / 5) * 5 - 5) * perPAge,
    //     sliceIndex: Number(values[remainder]),
    //   });
    //   setPage(value);
    // }
  };

  const handleSearchChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string,
  ) => {
    event.stopPropagation();
    if (newAlignment) {
      // if (newAlignment !== '1') {
      //   setBooleanSearch('');
      // } else {
      //   filterEdit('skillData', []);
      // }
      setCandidateJobFilter({
        ...candidateJobFilter,
        booleanSearch: '',
        skillData: [],
        title: '',
      });
      // filterEdit('title', '');
      // filterEdit('skillData', []);
      setTabSearch(newAlignment);
    }
  };

  const roundNearest5 = (num: number) => {
    const rount5 = Math.ceil(num / perPAge) * perPAge;
    return rount5 / perPAge;
  };

  const [jobClosingStatus, setJobClosingStatus] = React.useState('filled');

  const onSubmit = (data: any) => {
    const formData = new FormData();
    const jobsArr = [];
    const wsIds = [];
    jobData?.jobWorkStatus?.map((e) => {
      wsIds.push(e.workStatus.id);
    });
    jobData.jobSkills.map((item: any) => {
      jobsArr.push({
        preffered: item.preffered,
        required: item.required,
        rowId: item.id,
        skillId: item.skillId,
      });
    });

    formData.append('customerId', jobData?.customerId);
    formData.append('certificates', jobData?.certificates);
    formData.append('organizationId', userInfo.organizationId);
    formData.append('city', jobData?.city);
    formData.append('education', jobData?.education);
    formData.append('educationNotes', jobData?.educationNotes);
    formData.append('interviewType', jobData?.interviewType);
    formData.append('jobDurationFrom', jobData?.jobDurationFrom);
    formData.append('jobDurationTo', jobData?.jobDurationTo);
    formData.append('jobTitle', jobData?.jobTitle);
    formData.append('jobsSkills', JSON.stringify(jobsArr));
    formData.append('notes', jobData?.notes);
    formData.append('payRangeFrom', jobData?.payRangeFrom);
    formData.append('payRangeTo', jobData?.payRangeTo);
    formData.append('zip', jobData?.zip);
    formData.append('workType', jobData?.workType);
    formData.append('jobWorkStatusIds', JSON.stringify(wsIds));
    formData.append('state', jobData?.state);
    formData.append('startDate', jobData?.startDate);
    formData.append('projectRole', jobData?.projectRole);
    formData.append('positionType', jobData?.positionType);
    formData.append('payRateType', jobData?.payRateType);
    formData.append('jobDescription', jobData?.jobDescription);
    formData.append(
      'salaryDesired',
      jobData['salaryDesired'] ? jobData['salaryDesired'] : 0,
    );
    // formData.append('position', jobData?.position);
    // formData.append('status', 'closed');
    formData.append('status', jobClosingStatus);
    formData.append('publish', jobData?.publish);
    if (jobData.id) {
      formData.append('removeRowId', JSON.stringify([]));
      formData.append('removeFileId', JSON.stringify([]));
      updateData(jobData.id, formData, MASTER_ENDPOINT.Jobs, true).then(
        (resp: any) => {
          dispatch({
            type: USER_RELOAD,
          });
          handleClosedelete();
          navigation('/recruiter');
        },
      );
    }
  };

  const [fileView, setFileView] = React.useState<any>({
    open: false,
    row: {},
  });
  const handleViewOpen = (row: any) =>
    setFileView({
      open: true,
      row,
    });
  const handleViewClose = () =>
    setFileView({
      open: false,
      row: {},
    });
  const [viewRow, setViewRow] = React.useState<any>({});

  const [SuccessUPRESLoader, setSuccessUPRESLoader] =
    React.useState<any>(false);
  const [ResumeuploadLoader, setResumeuploadLoader] =
    React.useState<any>(false);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [skillData, setSkillData] = React.useState<any>([]);
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? 'spring-popper' : undefined;

  const [pocOpen, setPOCOpen] = React.useState(false);
  const [pocAnchorEl, setPOCAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [pocData, setPOCData] = React.useState<any>({});
  const canPOCBeOpen = pocOpen && Boolean(pocAnchorEl);
  const pocId = canPOCBeOpen ? 'spring-popper' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>, skill: any) => {
    setSkillData(skill.slice(4));
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handlePOCClick = (
    event: React.MouseEvent<HTMLElement>,
    poc: {
      pocUserName: string;
      pocEmail: string;
    },
  ) => {
    setPOCData(poc);
    setPOCAnchorEl(event.currentTarget);
    setPOCOpen((previousOpen) => !previousOpen);
  };

  const [resumeOpen, setResumeOpen] = React.useState<any>({
    item: {},
    notes: [],
    open: false,
  });
  const handleResumeOpen = (item) => {
    setViewRow(item);
    getNotes(item);
  };
  const handleResumeClose = () => {
    setResumeOpen({
      item: {},
      notes: [],
      open: false,
    });
  };

  const getNotes = (item?: any) => {
    getAllListData(
      MASTER_ENDPOINT.Candidates + '/notes?id=&userId=&candidateId=' + item.id,
    )
      .then((resp: any) => {
        const resumeAcceptAndRejectInfo =
          resp?.resume && resp?.resume?.comments ? resp?.resume : null;
        const notesResponce = [
          ...(resumeAcceptAndRejectInfo
            ? [
                {
                  ...resumeAcceptAndRejectInfo,
                  notes: resumeAcceptAndRejectInfo?.comments,
                  user: resumeAcceptAndRejectInfo?.createdBy,
                },
              ]
            : []),
          ...(resp?.data && Array.isArray(resp.data) && resp.data.length
            ? resp.data
            : []),
        ];
        setResumeOpen({
          ...resumeOpen,
          item: item,
          notes: notesResponce,
          open: true,
        });
      })
      .catch((err: any) => {});
  };

  const favouriteCandidateToggle = (item: any, status: boolean) => {
    if (status) {
      setLoading(true);
      setMatchedList((pre) => [
        ...pre.map((el: any) =>
          el.id === item.id ? { ...el, favoriteStatus: !status } : el,
        ),
      ]);
      deleteData(
        item.favoriteId,
        MASTER_ENDPOINT.Candidates + '/favorite',
        false,
      ).then((resp) => {
        setLoading(false);
      });
    } else {
      const favObj = {
        candidateId: item.id,
        jobId: jobId,
      };
      addNewData(
        favObj,
        MASTER_ENDPOINT.Candidates + '/favorite',
        {},
        false,
      ).then((resp: any) => {
        setParam((pre) => ({ ...pre, reload: !param.reload }));
      });
    }
  };

  const cList = React.useCallback((row: any, index: number) => {
    const skillData = row?.candidateSkills.slice(0, 4);
    let sName = '';
    if (skillData && skillData?.length) {
      skillData.map((item: any) => {
        sName += item ? item?.skillName + ', ' : '';
      });
    }
    if (sName) {
      sName = sName.replace(/,\s*$/, '');
    }

    return (
      <Typography component={'div'} className="match-item" key={index}>
        <Grid container>
          <Grid item md={6} sm={12}>
            <Typography className="match-title">
              {row?.firstName} {row?.lastName}
            </Typography>
          </Grid>

          <Grid item md={6} sm={12} className="d-flex-jae">
            <Typography component={'div'} className="d-flex-a">
              {['W2 Available', 'W2 OnAssignment', 'W2 Prospective'].includes(
                row.applicantStatus,
              ) &&
                row.applicantStatus !== 'None' && (
                  <Typography
                    className={
                      row.applicantStatus === 'W2 Available'
                        ? 'wa-badge d-flex-ja'
                        : row.applicantStatus === 'W2 Prospective'
                        ? 'wa-badge-prospective d-flex-ja'
                        : 'wa-badge-assignment d-flex-ja'
                    }>
                    {row.applicantStatus}
                  </Typography>
                )}
              {userInfo.roleId !== 1 && (
                <img
                  src={row.favoriteStatus ? StarActiveIcon : StarInActiveIcon}
                  alt="Star Icon"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    favouriteCandidateToggle(row, row.favoriteStatus);
                  }}
                />
              )}
              {/* <StarIcon className="red-fill" /> */}
            </Typography>
          </Grid>
        </Grid>
        <Typography className="match-addres">
          {`${row?.city ? String(row?.city) : ''} ${
            row?.state && row?.city ? ',' : ''
          } ${row?.state ? `${String(row?.state)}` : ''}`}
          {/* {row?.city && row?.state
            ? row?.city + ', ' + row?.state
            : row?.city + row?.state} */}
        </Typography>
        <Typography className="nm-txt">{row?.jobTitle}</Typography>
        <Typography className="nm-txt">
          {sName}
          {skillData?.length !== row?.candidateSkills.length ? (
            <button
              className="more-buttom"
              aria-describedby={id}
              type="button"
              onClick={(e) => handleClick(e, row.candidateSkills)}>
              More...
            </button>
          ) : (
            ''
          )}
        </Typography>
        <Typography component={'div'} className="d-flex-a">
          <Typography className="a-link" onClick={() => handleResumeOpen(row)}>
            View Resume
          </Typography>
          {userInfo.roleId !== 1 && (
            <a
              className="a-link"
              target="_blank"
              onClick={(e) => {
                window.location.href = `mailto:${String(row?.email)}`;
                e.preventDefault();
              }}>
              Contact
            </a>
          )}
          <a
            className="a-link"
            onClick={(e) =>
              handlePOCClick(e, {
                pocEmail: row.pocEmail,
                pocUserName: row.pocUserName,
              })
            }>
            POC
          </a>
        </Typography>
        {row?.candidateNotes && (
          <Typography component={'div'} className="lat-box">
            <Typography className="lat-title">Latest Notes</Typography>
            <Typography className="lat-dis">
              {row.candidateNotes.length > 180
                ? row.candidateNotes.substr(0, 180) + '...'
                : row.candidateNotes}
            </Typography>
            <Typography className="a-link">
              <a className="view-more" onClick={() => handleResumeOpen(row)}>
                View All Notes
              </a>
            </Typography>
          </Typography>
        )}
      </Typography>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const candidateData = React.useMemo(() => {
    if (loading) {
      return (
        <Typography component={'div'} className="match-item">
          <Typography
            component={'div'}
            sx={{
              justifyContent: 'center',
              paddingTop: '20px',
              textAlign: 'center',
            }}>
            <CircularProgress sx={{ color: '#340000' }} />
          </Typography>
        </Typography>
      );
    } else {
      if (matchedList && matchedList.length) {
        return matchedList.map((row: any, index: number) => {
          return cList(row, index);
        });
      } else {
        return (
          <Typography component={'div'}>
            <Typography
              component={'div'}
              className="sub-info"
              sx={{
                color: '#000000',
                fontFamily: 'Red Hat Display SemiBold !important',
                fontSize: '15px !important',
                justifyContent: 'center',
                paddingTop: '20px',
                textAlign: 'center',
              }}>
              Matched Candidates Not Found
            </Typography>
          </Typography>
        );
      }
    }
  }, [cList, matchedList, loading]);

  useEffect(() => {
    if (Object.keys(param).length) {
      getCandidateData();
    }
  }, [getCandidateData, param]);

  async function ResumeSubmit(sourceId: any, Files: any) {
    const formData = new FormData();
    formData.append('sourceId', sourceId);
    formData.append('file', Files && Files[0]);
    formData.append('jobPostingId', jobId);
    formData.append('type', 'jobResume');

    setResumeuploadLoader(true);
    setSuccessUPRESLoader(false);

    try {
      const resp = await addNewData(
        formData,
        RESUME_ENDPOINT.ResumeValidation,
        {},
        false,
      );

      if (resp) {
        setSuccessUPRESLoader(true);
        setResumeuploadLoader(false);
      }
    } catch (error) {
      setSuccessUPRESLoader(false);
      toast.error(error, alertOptions);
    } finally {
      setResumeuploadLoader(false);
    }
  }

  return (
    <LoadingOverlay active={loader} spinner text="loading...">
      <Typography component={'div'}>
        <Grid container>
          {Object.keys(jobData).length ? (
            <Grid item md={8.5} sm={12}>
              <Typography component={'div'} className="jobview-header d-flex-a">
                <Grid container>
                  <Grid
                    item
                    md={2}
                    sm={4}
                    className="d-flex-a"
                    onClick={goBackFun}>
                    <img
                      src={BackIcon}
                      alt="Icon"
                      style={{ cursor: 'pointer', marginLeft: '21px' }}
                    />
                    <Typography className="back">Back</Typography>
                  </Grid>
                  <Grid
                    item
                    md={10}
                    sm={8}
                    className="d-flex-a flex-sb"
                    sx={
                      userInfo?.roleId === 3
                        ? { paddingRight: '32px !important' }
                        : { paddingRight: '32px !important' }
                    }>
                    <Grid container gap={'51px'} sx={{ alignSelf: 'center' }}>
                      <Typography
                        className={`job-dis ${
                          jobtab !== ViewJobConstants.Resumes
                            ? 'job-dis-activ'
                            : ''
                        }`}
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          history(
                            `/${
                              userInfo.roleId === 5 ? 'sales' : 'recruiter'
                            }/job-post/view-job/${
                              ViewJobConstants.myPosting
                            }/${jobId}`,
                          )
                        }>
                        Job Descriptions
                      </Typography>
                      <Typography
                        className={`job-dis ${
                          jobtab === ViewJobConstants.Resumes
                            ? 'job-dis-activ'
                            : ''
                        }`}
                        sx={{ cursor: 'pointer' }}
                        onClick={() =>
                          history(
                            `/${
                              userInfo.roleId === 5 ? 'sales' : 'recruiter'
                            }/job-post/view-job/${
                              ViewJobConstants.Resumes
                            }/${jobId}`,
                          )
                        }>
                        Resumes
                      </Typography>
                    </Grid>
                    {location.pathname.includes('my-posting') && (
                      <Grid
                        item
                        md={2}
                        sm={4}
                        sx={{ marginRight: '20px !important' }}>
                        <Typography
                          component={'div'}
                          className="d-flex-a"
                          sx={{
                            gap: '20px',
                            justifyContent: 'flex-end',
                          }}>
                          {userInfo.roleId === 5 && (
                            <Button
                              className="edit-btn"
                              onClick={handleClickOpen('body')}>
                              Edit
                            </Button>
                          )}
                          {open1 && (
                            <AddAndEditJob
                              workStatusList={workStatus}
                              open={open1}
                              editId={jobData.id}
                              editRow={jobData}
                              scroll={scroll}
                              onClose={handleClose1}
                            />
                          )}
                          {userInfo?.roleId === 3 ? (
                            <Button
                              className="close-btn"
                              sx={{ minWidth: '100px' }}
                              onClick={handleOpendelete}>
                              Close Job
                            </Button>
                          ) : null}
                          <Modal
                            className="job-close-modal"
                            open={opend}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description">
                            <Box
                              sx={style}
                              className="confirmation-widget d-flex-ja">
                              <IconButton
                                aria-label="Close"
                                onClick={handleClosedelete}
                                sx={{
                                  position: 'absolute',
                                  right: 5,
                                  top: 5,
                                }}>
                                <CloseIcon />
                              </IconButton>
                              <img src={CloseJob} alt="delete" />
                              <Typography
                                sx={{
                                  fontSize: '24px !important',
                                  margin: '19px 0 28px 0',
                                }}>
                                Are you sure you want to close out this job?
                              </Typography>
                              <Grid
                                container
                                sx={{
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  marginBottom: '20px',
                                }}>
                                <Grid item md={6} sm={6}>
                                  <Typography component={'div'}>
                                    <label>Reason</label>
                                    <span className="errorText">{'*'}</span>
                                    <CustomSelect
                                      onChange={(e: any) => {
                                        setJobClosingStatus(e?.target?.value);
                                      }}
                                      value={jobClosingStatus}
                                      labelKey={'name'}
                                      valueKey={'id'}
                                      options={JOBCLOSINGSTATUS}
                                      placeHolder={''}
                                      className={'common-select'}
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Typography
                                className="confirm-btn model-btn"
                                component={'div'}>
                                <Button
                                  className="no-btn"
                                  onClick={handleClosedelete}
                                  sx={{
                                    borderRadius: '8px',
                                    textTransform: 'capitalize',
                                  }}>
                                  No, Cancel
                                </Button>
                                <Button
                                  className="job-delete-btn"
                                  onClick={onSubmit}
                                  sx={{
                                    borderRadius: '8px',
                                    textTransform: 'capitalize',
                                  }}>
                                  YES, Close Out Job
                                </Button>
                              </Typography>
                            </Box>
                          </Modal>
                        </Typography>
                      </Grid>
                    )}

                    {(userDetail?.roleId === 3 || userDetail?.roleId === 5) && (
                      <CommonUploadBtn
                        onPress={CloseFn}
                        name={'Upload Resume'}
                        Icon={UpArrowSvgIcon}
                        width="148px"
                        svgStyle={{
                          height: '15px',
                          width: '13px',
                        }}
                      />
                    )}
                    <UploadResumeModal
                      open={opening}
                      ClickFn={CloseFn}
                      SubmitFn={ResumeSubmit}
                      Success={SuccessUPRESLoader}
                      Loader={ResumeuploadLoader}
                      JobListingData={JobListingData}
                      JobTitle={
                        jobData && jobData.jobTitle ? jobData.jobTitle : ''
                      }
                      Customer={
                        jobData && jobData.customer && jobData.customer.name
                          ? jobData.customer.name
                          : ''
                      }
                      headerShown
                    />
                  </Grid>
                </Grid>
              </Typography>
              <Grid container className="job-view-body">
                <Grid item md={2} sm={12} />
                <Grid item md={8} sm={12}>
                  <Typography component={'div'}>
                    <Typography component={'div'} className="job-h-titles">
                      <Typography
                        component={'div'}
                        className="d-flex-a flex-sb">
                        <Typography className="in-h">
                          {jobData?.jobTitle}
                        </Typography>
                        {jobData.status === 'published' ? (
                          <Typography
                            component={'div'}
                            className="post-badge d-flex-ja">
                            Posted
                          </Typography>
                        ) : jobData.status === 'closed' ? (
                          <Typography
                            component={'div'}
                            className="tick-badge d-flex-ja">
                            <img src={CheckIcon} alt="Tick" />
                            Filled
                          </Typography>
                        ) : (
                          <Typography
                            component={'div'}
                            className="match-badge d-flex-ja">
                            {capitalize(jobData.status)}
                          </Typography>
                        )}
                      </Typography>
                      <Typography className="sub-info">
                        <span className="comp-name">
                          {jobData?.customer?.name}
                        </span>
                        <span className="lr">
                          {jobData?.city}, {jobData?.state}
                        </span>{' '}
                        <span className="lr">({jobData?.workType})</span>{' '}
                        <span className="days">
                          {timeSince(jobData?.createdAt)} ago
                        </span>
                      </Typography>
                    </Typography>
                    <Typography component={'div'} className="contain-box">
                      <Grid container columnSpacing={1.5}>
                        <Grid item md={3} sm={12}>
                          <Typography className="contain-title">
                            Project Role
                          </Typography>
                        </Grid>
                        <Grid item md={9} sm={12}>
                          <Typography className="contain-info">
                            {jobData?.projectRole}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography component={'div'} className="contain-box">
                      <Grid container columnSpacing={1.5}>
                        <Grid item md={3} sm={12}>
                          <Typography className="contain-title">
                            Job Description
                          </Typography>
                        </Grid>
                        <Grid item md={9} sm={12}>
                          <Typography
                            className="contain-info"
                            dangerouslySetInnerHTML={{
                              __html: jobData?.jobDescription
                                ? jobData?.jobDescription
                                : '-',
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                    <Typography component={'div'} className="contain-box">
                      <Grid container columnSpacing={1.5}>
                        <Grid item md={3} sm={12}>
                          <Typography className="contain-title">
                            Educations
                          </Typography>
                        </Grid>
                        <Grid item md={9} sm={12}>
                          <Typography className="contain-info">
                            {jobData?.education} |{' '}
                            <span className="degree">
                              {' '}
                              {jobData?.educationNotes}
                            </span>{' '}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                    <Grid
                      container
                      columnSpacing={2.5}
                      rowSpacing={3}
                      sx={{ paddingTop: '20px' }}>
                      <Grid item md={4} sm={12}>
                        <Typography
                          component={'div'}
                          className="count-job-box d-flex-ja range">
                          <Typography>
                            {jobData?.payRateType === 'Salary'
                              ? `$${Number(jobData?.salaryDesired)}`
                              : `$${Number(jobData?.payRangeFrom)} - $${Number(
                                  jobData?.payRangeTo,
                                )}`}
                          </Typography>
                          <Typography className="c-text">Pay Rate</Typography>
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12}>
                        <Typography
                          component={'div'}
                          className="count-job-box d-flex-ja date">
                          <Typography>
                            {moment(jobData?.startDate).format('MM/DD/YYYY')}
                          </Typography>
                          <Typography className="c-text">
                            Project Start Date
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12}>
                        <Typography
                          component={'div'}
                          className="count-job-box d-flex-ja hired">
                          <Typography>{jobData?.positionType}</Typography>
                          <Typography className="c-text">
                            Position Type
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      columnSpacing={2}
                      rowSpacing={2}
                      sx={{ paddingTop: '24px' }}>
                      <Grid item md={4} sm={12}>
                        <Typography className="l-tit">
                          Interview Type:
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <Typography>{jobData?.interviewType}</Typography>
                      </Grid>
                      <Grid item md={4} sm={12}>
                        <Typography className="l-tit">Duration:</Typography>
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <Typography>
                          {jobData?.jobDurationTo}
                          {jobData?.jobDurationTo === 24 && '+'} Months
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12}>
                        <Typography className="l-tit">Location:</Typography>
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <Typography>
                          {jobData?.city}, {jobData?.state}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12}>
                        <Typography className="l-tit">Work Status:</Typography>
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <Typography>
                          {jobData?.jobWorkStatus &&
                          jobData?.jobWorkStatus.length ? (
                            jobData?.jobWorkStatus.map(
                              (item: any, index: number) => {
                                return `${String(item?.workStatus?.name)}${
                                  index === jobData?.jobWorkStatus.length - 1
                                    ? ''
                                    : ', '
                                }`;
                              },
                            )
                          ) : (
                            <Typography />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12}>
                        <Typography className="l-tit">
                          Certifications:
                        </Typography>
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <Typography>
                          {jobData?.certificates ? jobData?.certificates : '-'}
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12}>
                        <Typography className="l-tit">Notes:</Typography>
                      </Grid>
                      <Grid item md={8} sm={12}>
                        <Typography>{jobData?.notes}</Typography>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ paddingTop: '24px' }}>
                      <Grid item md={7} sm={12}>
                        <Typography component={'div'} className="exp-table">
                          <table>
                            <thead>
                              <tr>
                                <th className="w-50">Skills</th>
                                <th className="w-25"># Yrs Required</th>
                                <th className="w-23">Preferred</th>
                              </tr>
                            </thead>
                            <tbody>
                              {jobData?.jobSkills &&
                                jobData?.jobSkills.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="w-50">
                                        {item?.skill?.name}
                                      </td>
                                      <td
                                        className="w-25"
                                        style={{
                                          textAlign: 'left',
                                        }}>
                                        {item?.required}
                                      </td>
                                      <td
                                        className="w-23"
                                        style={{
                                          textAlign: 'left',
                                        }}>
                                        {item?.preffered}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography component={'div'} className="contain-box-file">
                      <Grid container columnSpacing={1.5}>
                        <Grid item md={4} sm={12}>
                          <Typography className="contain-title">
                            Files
                          </Typography>
                        </Grid>
                        <Grid item md={8} sm={12}>
                          {jobData?.files && jobData?.files.length
                            ? jobData?.files.map((item: any, index: number) => {
                                return (
                                  <Typography
                                    onClick={() => handleViewOpen(item)}
                                    className="contain-info"
                                    key={index}
                                    component={'span'}>
                                    {item.fileName}
                                    {jobData?.files.length !== index + 1 ? (
                                      <>,&nbsp;&nbsp;&nbsp;</>
                                    ) : (
                                      ''
                                    )}
                                  </Typography>
                                );
                              })
                            : '-'}
                        </Grid>
                      </Grid>
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item md={2} sm={12} />
              </Grid>
            </Grid>
          ) : (
            <Grid item md={8.5} sm={12} />
          )}
          <Grid item md={3.5} sm={12}>
            <Typography component={'div'} className="side-body">
              <Typography
                component={'div'}
                className="d-flex-a flex-sb side-head">
                <Typography className="titles">Matched Candidates</Typography>
                {userInfo.roleId !== 1 && (
                  <Typography component={'div'} className="d-flex-a">
                    {matchedList.length > 0 && (
                      <Button className="header-filter-box mr-10">
                        <MailOutlineIcon
                          sx={{ fill: '#0262B9', fontSize: '1.9rem' }}
                          onClick={(e) => {
                            sendMailToAll(e);
                          }}
                        />
                      </Button>
                    )}
                    <Button
                      className="header-filter-box"
                      onClick={() => setOpenFilter(true)}>
                      <img src={FilterIcon} alt="Filter" />
                    </Button>
                    <Drawer
                      className="filter-drawer"
                      open={openfilter}
                      anchor={'right'}
                      onClose={() => setOpenFilter(false)}>
                      <Typography className="dra-title ">Filter</Typography>
                      <form>
                        <Grid container>
                          <Grid item md={12} sm={12}>
                            <Typography className="row-pad">
                              <label className="dra-f-title">Company</label>
                              <CustomSelect
                                onChange={(e: any) => {
                                  filterEdit(
                                    'organizationId',
                                    e?.target?.value,
                                  );
                                }}
                                value={candidateJobFilter?.organizationId}
                                labelKey={'name'}
                                valueKey={'id'}
                                options={
                                  companyList && companyList?.length > 0
                                    ? companyList
                                        ?.filter(
                                          (cmpny) => cmpny.name === 'CoxLittle',
                                        )
                                        ?.map((cmp) => ({
                                          id: cmp.id,
                                          name: cmp.name,
                                        }))
                                    : []
                                }
                                placeHolder={''}
                                className={'common-select'}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12}>
                            <Typography className="row-pad">
                              <Accordion expanded={true}>
                                <AccordionSummary
                                  // sx={{ display: 'flex' }}
                                  expandIcon={
                                    <ExpandMoreIcon
                                      style={{ display: 'none' }}
                                    />
                                  }
                                  aria-controls="panel1a-content"
                                  id="panel1a-header">
                                  <Typography
                                    component={'div'}
                                    sx={{ display: 'flex' }}>
                                    <label className="dra-f-title">
                                      Skills
                                    </label>
                                    <Typography
                                      component={'div'}
                                      sx={{
                                        display: 'flex',
                                        position: 'absolute',
                                        // right: '30px',
                                        right: '0px',
                                      }}>
                                      <ToggleButtonGroup
                                        // color="primary"
                                        value={tabSearch}
                                        exclusive
                                        onChange={handleSearchChange}
                                        aria-label="Platform">
                                        <ToggleButton
                                          value="1"
                                          sx={{
                                            background:
                                              tabSearch === '1'
                                                ? '#636366 !important'
                                                : 'rgba(118,118,128,0.24)',
                                            border: '0px',
                                            color: 'white !important',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                            height: '30px',
                                            justifyContent: 'center',
                                            paddingTop: '13px',
                                            textTransform: 'capitalize',
                                          }}>
                                          Quick Search
                                        </ToggleButton>
                                        <ToggleButton
                                          value="2"
                                          sx={{
                                            background:
                                              tabSearch === '2'
                                                ? '#636366 !important'
                                                : 'rgba(118,118,128,0.24)',
                                            border: '0px',
                                            color: 'white !important',
                                            fontSize: '13px',
                                            fontWeight: 'bold',
                                            height: '30px',
                                            justifyContent: 'center',
                                            paddingTop: '13px',
                                            textTransform: 'capitalize',
                                            width: '85px',
                                          }}>
                                          Boolean
                                        </ToggleButton>
                                      </ToggleButtonGroup>
                                    </Typography>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {tabSearch === '1' ? (
                                    <>
                                      <CustomSelectSeach
                                        value={{}}
                                        onChange={(e) => {
                                          if (e) {
                                            const fIdx =
                                              candidateJobFilter?.skillData.findIndex(
                                                (item) => item.id === e.id,
                                              );
                                            if (fIdx === undefined) {
                                              filterEdit('skillData', [e]);
                                            }
                                            if (fIdx === -1) {
                                              filterEdit('skillData', [
                                                ...candidateJobFilter.skillData,
                                                e,
                                              ]);
                                            }
                                          }
                                        }}
                                        type={'multiple'}
                                        className={'skill-job-select'}
                                      />
                                      <Typography
                                        component={'div'}
                                        className="d-flex-a"
                                        sx={{ flexWrap: 'wrap' }}>
                                        {candidateJobFilter?.skillData &&
                                        candidateJobFilter?.skillData.length >
                                          0 ? (
                                          candidateJobFilter?.skillData.map(
                                            (item: any, index: number) => {
                                              return (
                                                <Typography
                                                  className="search-item"
                                                  key={index}>
                                                  {item?.name}
                                                  <Typography
                                                    onClick={() => {
                                                      filterEdit(
                                                        'skillData',
                                                        candidateJobFilter?.skillData.filter(
                                                          (row: any) =>
                                                            row.id !== item?.id,
                                                        ),
                                                      );
                                                    }}>
                                                    <CloseIcon />
                                                  </Typography>
                                                </Typography>
                                              );
                                            },
                                          )
                                        ) : (
                                          <Typography />
                                        )}
                                      </Typography>
                                    </>
                                  ) : (
                                    <CustomInput
                                      isTextArea={true}
                                      className={classes.customTextarea}
                                      placeHolder="input..."
                                      value={candidateJobFilter?.booleanSearch}
                                      onChange={(e: any) => {
                                        filterEdit(
                                          'booleanSearch',
                                          e?.target?.value,
                                        );
                                      }}
                                      minRows={5}
                                    />
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12}>
                            <Typography className="row-pad">
                              <label className="dra-f-title">Title</label>
                              {tabSearch !== '1' ? (
                                <CustomInput
                                  isTextArea={true}
                                  className={classes.customTextarea}
                                  placeHolder="input..."
                                  value={candidateJobFilter?.title}
                                  onChange={(e: any) => {
                                    filterEdit('title', e?.target?.value);
                                  }}
                                  minRows={5}
                                />
                              ) : (
                                <CustomInput
                                  placeHolder=""
                                  // variant="outlined"
                                  onChange={(e) => {
                                    filterEdit('title', e?.target?.value);
                                  }}
                                  value={candidateJobFilter?.title}
                                  className={classes.custominput}
                                />
                              )}
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12}>
                            <Typography className="row-pad">
                              <label className="dra-f-title">Location</label>
                              <CustomInput
                                placeHolder=""
                                // variant="outlined"
                                onChange={(e) => {
                                  filterEdit('location', e?.target?.value);
                                }}
                                value={candidateJobFilter?.location}
                                className={classes.custominput}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12}>
                            <Typography className="row-pad">
                              <label className="dra-f-title">Work Auth</label>
                              {/* <CustomSelect
                                onChange={(e: any) => {
                                  filterEdit('workAuth', e?.target?.value);
                                }}
                                value={candidateJobFilter?.workAuth}
                                labelKey={'name'}
                                valueKey={'id'}
                                options={workStatus}
                                placeHolder={''}
                                className={'common-select'}
                              /> */}
                              <CustomMultiSelect
                                onChange={(e: any) => {
                                  filterEdit('workAuth', e?.target?.value);
                                }}
                                value={
                                  candidateJobFilter &&
                                  candidateJobFilter?.workAuth
                                    ? candidateJobFilter?.workAuth
                                    : []
                                }
                                labelKey={'name'}
                                valueKey={'id'}
                                placeHolder={''}
                                className={'common-select'}
                                options={
                                  workStatus && workStatus.length
                                    ? workStatus
                                    : []
                                }
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={12} sm={12}>
                            <Typography
                              className="d-flex-a flex-sb"
                              sx={{ padding: '15px 12px' }}>
                              <Button
                                className="m-blue-btn mr-8"
                                onClick={resetFilter}>
                                Reset
                              </Button>
                              <Button
                                className="m-blue-btn"
                                onClick={applyFilter}>
                                Apply
                              </Button>
                            </Typography>
                          </Grid>
                        </Grid>
                      </form>{' '}
                    </Drawer>
                  </Typography>
                )}
              </Typography>
              <Typography component={'div'} className="match-body">
                {candidateData}
              </Typography>
              <Typography component={'div'} className="page-count">
                {matchedList && matchedList.length ? (
                  <Pagination
                    count={roundNearest5(matchedTotal)}
                    page={Object.keys(param).length ? page : 0}
                    onChange={handleChange}
                  />
                ) : (
                  <Typography />
                )}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Typography>
      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Popper id={id} open={open} anchorEl={anchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Box className={'show-more-box'}>
                  {skillData && skillData.length > 0
                    ? skillData.map((row: any, index: number) => {
                        return (
                          row?.skillName +
                          (index + 1 === skillData.length ? '' : ', ')
                        );
                      })
                    : ''}
                </Box>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
      {pocOpen && (
        <ClickAwayListener onClickAway={() => setPOCOpen(false)}>
          <Popper id={pocId} open={pocOpen} anchorEl={pocAnchorEl} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Box className={'show-more-box'}>
                  {pocData.pocUserName && (
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}>
                      <Typography className="l-tit">Name :</Typography>
                      <Typography
                        sx={{
                          fontSize: '15px !important',
                          marginLeft: '5px',
                        }}>
                        {pocData.pocUserName}
                      </Typography>
                    </Typography>
                  )}
                  {pocData.pocEmail && (
                    <Typography
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}>
                      <Typography className="l-tit">Email :</Typography>
                      <Typography
                        sx={{
                          fontSize: '15px !important',
                          marginLeft: '5px',
                          textTransform: 'none !important',
                        }}>
                        {pocData.pocEmail}
                      </Typography>
                    </Typography>
                  )}
                  {!pocData.pocUserName && !pocData.pocEmail && (
                    <Typography
                      sx={{
                        fontFamily: 'Red Hat Display SemiBold !important',
                        fontSize: '15px !important',
                      }}>
                      {"POC doesn't exist"}
                    </Typography>
                  )}
                </Box>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      )}
      <Modal
        open={addOpen}
        // onClose={handleAddClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={stylenote}>
          <IconButton
            aria-label="Close"
            onClick={handleAddClose}
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
            }}>
            <CloseIcon />
          </IconButton>
          <form onSubmit={handleSubmit(submitForm)}>
            <Typography className="recrutor-title">Add Notes</Typography>
            <Grid container>
              <Grid item md={12} sm={12}>
                <Typography>
                  <label>Notes</label>
                  <span className="errorText">{'*'}</span>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="notes"
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <CustomInput
                          isTextArea={true}
                          className={classes.customTextarea}
                          placeHolder=""
                          value={value}
                          ref={ref}
                          onChange={(e: any) => {
                            onChange(e);
                          }}
                          error={formState.errors?.notes?.message}
                          minRows={5}
                        />
                      );
                    }}
                  />
                </Typography>
                <Typography
                  component={'div'}
                  className="d-flex-a flex-sb"
                  sx={{ marginTop: '30px !important' }}>
                  <Button className="cancel-btn" onClick={resetForm}>
                    Cancel
                  </Button>
                  <Button className="save-btn" type={'submit'}>
                    Save
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Modal
        className="view-resume"
        open={fileView.open}
        // onClose={handleViewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={fileStyle}>
          <IconButton
            aria-label="Close"
            onClick={handleViewClose}
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
            }}>
            <CloseIcon />
          </IconButton>
          <Grid container sx={{ height: '100%' }}>
            <Grid item md={12} sm={12} xs={12}>
              <Typography
                component={'div'}
                sx={{ padding: '50px 50px 10px 50px' }}>
                <Typography
                  className="add-m-title"
                  sx={{ marginBottom: '12px' }}>
                  File
                </Typography>
                <Typography
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '30px',
                  }}>
                  <Typography>{fileView.row.fileName}</Typography>

                  <Typography
                    component={'div'}
                    sx={{
                      color: '#0A84FF',
                      cursor: 'pointer',
                      fontFamily: 'Red Hat Display Bold !important',
                      fontSize: '15px !important',
                      textDecoration: 'underline',
                      zIndex: 1,
                    }}
                    onClick={() => {
                      const baseName = fileView.row.fileName.slice(
                        0,
                        fileView.row.fileName.lastIndexOf('.'),
                      );
                      return saveAs(
                        `${process.env.REACT_APP_APIURL}${
                          MASTER_ENDPOINT.Files
                        }/${String(
                          fileView.row?.id,
                        )}?isDownload=true&userId=${String(userInfo?.id)}`,
                        // `${String(fileView.row.fileName)}`,
                        `${String(baseName)}.${String(fileView.row.extension)}`,
                        { autoBom: true },
                      );
                    }}>
                    Download
                  </Typography>
                </Typography>
                {fileView.row.id && (
                  <Typography
                    sx={{
                      height: '75vh',
                      width: '100%',
                    }}>
                    {/* <FileViewer
                      filePath={`${process.env.REACT_APP_APIURL}${
                        MASTER_ENDPOINT.Files
                      }/${String(fileView.row.id)}?userId=${String(
                        userInfo?.id,
                      )}`}
                      fileType={fileView.row.extension}
                    /> */}
                    <PdfViewer
                      url={`${process.env.REACT_APP_APIURL}${
                        MASTER_ENDPOINT.Files
                      }/${String(fileView.row.id)}?type=pdf&userId=${String(
                        userInfo?.id,
                      )}`}
                      fileName={fileView?.row?.fileName}
                      hideDownload={true}
                      from="Jobview"
                    />
                  </Typography>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal
        className="view-resume"
        open={resumeOpen.open}
        // onClose={handleResumeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={resumeStyle}>
          <IconButton
            aria-label="Close"
            onClick={handleResumeClose}
            sx={{
              position: 'absolute',
              right: 5,
              top: 5,
            }}>
            <CloseIcon />
          </IconButton>
          <Grid container sx={{ height: '100%' }}>
            <Grid item md={7.6} sm={12} xs={12}>
              <Typography
                component={'div'}
                sx={{ padding: '50px 50px 10px 50px' }}>
                <Typography
                  className="add-m-title d-flex-a flex-sb"
                  sx={{ marginBottom: '12px' }}>
                  Candidate Resume
                  {resumeOpen.item.awsResumePath ? (
                    <Typography
                      className="pointer"
                      component={'div'}
                      sx={{
                        color: '#0A84FF',
                        fontFamily: 'Red Hat Display Bold !important',
                        fontSize: '15px !important',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        const fullName = resumeOpen.item?.consultantName
                          ? resumeOpen.item?.consultantName
                          : `${String(resumeOpen.item?.firstName)} ${
                              resumeOpen.item?.lastName
                                ? String(resumeOpen.item?.lastName)
                                : ''
                            }`;
                        saveAs(
                          `${process.env.REACT_APP_APIURL}${
                            MASTER_ENDPOINT.Files
                          }/${String(
                            resumeOpen.item?.id,
                          )}?type=resume&isDownload=true&userId=${String(
                            userInfo?.id,
                          )}`,
                          `${String(fullName)}.${String(
                            resumeOpen.item.awsResumePath.split('.')?.[1],
                          )}`,
                          { autoBom: true },
                        );
                      }}>
                      Download
                    </Typography>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography sx={{ marginBottom: '12px' }}>
                  Resume Info
                </Typography>
                {resumeOpen.item.awsResumePath && (
                  <Typography>
                    <div className="pdf-app-views">
                      <div className="pdf-footer">
                        <div className="pdf-button-container">
                          <IconButton
                            aria-label="Zoom In"
                            onClick={handleZoomIn}
                            disabled={pageScale >= 3}
                            color="primary">
                            <ZoomInIcon />
                          </IconButton>
                          <IconButton
                            aria-label="Zoom Out"
                            onClick={handleZoomOut}
                            disabled={pageScale <= 0.3}
                            color="primary">
                            <ZoomOutIcon />
                          </IconButton>
                        </div>

                        <div className="pdf-page-text">
                          Page {pageNumber} of {totalPages}
                        </div>

                        <div className="pdf-button-container">
                          <IconButton
                            aria-label="Previous"
                            onClick={handlePrevious}
                            disabled={pageNumber === 1}
                            color="primary">
                            <ArrowBackIosIcon />
                          </IconButton>
                          <IconButton
                            aria-label="Next"
                            onClick={handleNext}
                            disabled={pageNumber === totalPages}
                            color="primary">
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </div>
                      </div>
                      <div className="pdf-page-container">
                        <Document
                          file={`${process.env.REACT_APP_APIURL}${
                            MASTER_ENDPOINT.Files
                          }/${String(viewRow.id)}?type=resume&userId=${String(
                            userInfo?.id,
                          )}`}
                          onLoadSuccess={onDocumentLoadSuccess}>
                          <Page pageNumber={pageNumber} scale={pageScale} />
                        </Document>
                      </div>
                    </div>
                    {/* <DocViewer
                      style={{
                        height: '75vh',
                        overflowY: 'scroll',
                      }}
                      prefetchMethod="GET"
                      requestHeaders={{
                        Authorization: token,
                      }}
                      documents={[
                        {
                          fileType: 'application/pdf',
                          uri: `${process.env.REACT_APP_APIURL}${
                            MASTER_ENDPOINT.Files
                          }/${String(resumeOpen.item.id)}?type=resume`,
                        },
                      ]}
                      config={{
                        csvDelimiter: ',',
                        header: {
                          disableFileName: true,
                          disableHeader: true,
                          retainURLParams: false,
                        },
                        pdfVerticalScrollByDefault: false,
                        pdfZoom: {
                          defaultZoom: 1, // 1 as default,
                          zoomJump: 0.1, // 0.1 as default,
                        },
                      }}
                      pluginRenderers={DocViewerRenderers}
                    /> */}
                    {/* 
                      <FileViewer
                        filePath={`${process.env.REACT_APP_APIURL}${
                          MASTER_ENDPOINT.Files
                        }/${String(resumeOpen.item.id)}?type=resume`}
                        fileType={
                          resumeOpen.item.awsResumePath
                            ? resumeOpen.item.awsResumePath.split('.')
                              ? resumeOpen.item.awsResumePath.split('.')?.[1]
                                ? resumeOpen.item.awsResumePath.split('.')?.[1]
                                : '1'
                              : '2'
                            : '3'
                        }
                      /> */}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              md={4.4}
              sm={12}
              xs={12}
              sx={{ backgroundColor: '#efefef' }}>
              <Typography
                component={'div'}
                sx={{ marginTop: '12px', padding: '33px 35px 10px 35px' }}>
                <Typography
                  component={'div'}
                  className="d-flex-a flex-sb"
                  sx={{ marginBottom: '12px' }}>
                  <Typography className="add-m-title">Notes</Typography>
                  {userInfo.roleId !== 1 && (
                    <Button
                      className="m-blue-btn "
                      onClick={() => {
                        handleAddOpen();
                      }}>
                      Add Notes
                    </Button>
                  )}
                  {/* <Typography className="add-m-title">Notes</Typography> */}
                </Typography>
                <Typography component={'div'} className="notes-box">
                  {resumeOpen.notes.length > 0 &&
                    resumeOpen.notes.map((data, index) => (
                      <Typography
                        key={index}
                        component={'div'}
                        className="notes-item"
                        style={{ minHeight: 'unset', wordBreak: 'break-all' }}>
                        <Typography>{data.notes}</Typography>
                        <Typography className={'spanNotes'}>
                          {data.user.firstName} {data.user.lastName}{' '}
                          {moment(data.createdAt).format('MM/DD/YYYY')}
                        </Typography>
                      </Typography>
                    ))}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </LoadingOverlay>
  );
};
