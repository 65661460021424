import { Typography } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import search from 'assets/images/search.svg';
import React, { ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';

const Search = styled('div')(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  borderRadius: theme.shape.borderRadius,
  marginLeft: 0,
  marginRight: theme.spacing(2),
  position: 'relative',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  padding: theme.spacing(0, 2),
  pointerEvents: 'none',
  position: 'absolute',
  zIndex: '1',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
  color: 'inherit',
}));

type InputProps = {
  onChange?: (src: any) => void;
  value?: string;
  placeHolderText?: string;
  staticWidth?: boolean;
};

const ResumeSearchBar = React.forwardRef((props: InputProps): ReactElement => {
  const [searchVal, setSearchVal] = useState('');
  const { searchText }: any = useAppSelector((store: any) => store.userLogin);
  const { onChange, placeHolderText, staticWidth } = props;

  useEffect(() => {
    if (searchText === '') {
      setSearchVal(searchText);
    }
  }, [searchText]);

  return (
    <Typography
      className={
        staticWidth ? 'resume-filter-search-w' : 'custom-resume-searchbar'
      }>
      <Search>
        <SearchIconWrapper>
          <img src={search} />
        </SearchIconWrapper>
        <StyledInputBase
          onChange={(e) => {
            if (
              e.target.value.startsWith(' ')
              // ||/[^a-zA-Z0-9\s]/.test(e.target.value) ||
              // /\d/.test(e.target.value)
            ) {
              return;
            } else {
              setSearchVal(e.target.value.trimStart());
              onChange && onChange(e);
            }
          }}
          placeholder={
            placeHolderText
              ? placeHolderText
              : 'Search by Candidate name,Phone number,Email'
          }
          value={searchVal}
          inputProps={{ 'aria-label': 'search', type: 'search' }}
        />
      </Search>
    </Typography>
  );
});

export default ResumeSearchBar;
