// import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Grid,
  // IconButton,
  Modal,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomDatePicker from 'components/DatePicker';
import ProjectSkillsAddSvg from 'components/SvgAsCode/ProjectSkillsAddSvg';
import ProjectSkillsTrashSvg from 'components/SvgAsCode/ProjectSkillsTrashSvg';
import _ from 'lodash';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import {
  ExperienceInfo as ExperienceInfoResponse,
  GeneralDetails,
} from 'types';

import addPlusIcon from '../../../../../assets/images/Add-plus.svg';
import AddIconExp from '../../../../../assets/images/Addiconexp.svg';
import ExpCloseIcon from '../../../../../assets/images/expcloseicon.svg';
import { CustomReactTagInput } from './CustomReactTagInput';

const style = {
  '&:focus-visible': {
    outline: 'none !important',
  },
  bgcolor: '#EEF7FF',
  borderRadius: '4px',
  bottom: 'auto',
  boxShadow: 24,
  left: '50%',
  minHeight: '20vh',
  p: 0,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
};

type Props = {
  control: UseFormReturn<GeneralDetails>;
  readOnly?: boolean;
  handleModalOpen?: any;
  invalidProject: ExperienceInfoResponse[];
  validProject: ExperienceInfoResponse[];
};
export const ExperienceInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl, readOnly, invalidProject } = props;
  const { control, formState, setValue, watch, trigger } = formControl;
  const [modalOpen, setModalOpen] = React.useState<{
    show: boolean;
    currentIndex: number;
  }>({
    currentIndex: -1,
    show: false,
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'experience',
  });

  const handleSkills = (id: number, value: any) => {
    return setValue(`experience.${id}.skillsMatched`, value);
  };

  const addField = () => {
    append({
      employerOrClient: '',
      fromDate: '',
      isCurrentlyWorking: false,
      jobTitle: '',
      location: '',
      project: '',
      skillsMatched: undefined,
      toDate: '',
    }); // Add a new field with a default value
  };
  const removeField = (idx: number) => {
    remove(idx);
  };

  const handleModalOpen = (currentIndex: number) => {
    setModalOpen({ currentIndex, show: true });
  };
  const handleModalClose = () => {
    setModalOpen({ currentIndex: -1, show: false });
  };

  return (
    <Typography component={'div'}>
      {fields && fields.length ? (
        fields.map((item, index: number) => (
          <Grid
            container
            spacing={1}
            key={item.id}
            sx={{
              // Add styles conditionally based on the first condition
              ...(fields.length === index + 1
                ? {}
                : {
                    borderBottom: '1px solid #ababff',
                    marginBottom: '10px',
                    paddingBottom: '10px',
                  }),
              // Add styles conditionally based on the second condition
              ...(invalidProject?.some((e) => item.loopId === e.loopId)
                ? {
                    backgroundColor: '#EEF7FF', // Example style for invalid projects
                  }
                : {
                    paddingRight: '10px',
                  }),
            }}>
            <Grid item md={2} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Period From
                <span className="red-asterisk"> *</span>
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`experience.${index}.fromDate`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomDatePicker
                        className="custom-datepicker"
                        views="MM/YYYY"
                        name={name}
                        onChange={(e) => {
                          onChange(e);
                          trigger(`experience.${index}.fromDate`);
                          if (!_.isEmpty(watch(`experience.${index}.toDate`))) {
                            trigger(`experience.${index}.fromDate`);
                            trigger(`experience.${index}.toDate`);
                          }
                        }}
                        maxDate={new Date()}
                        value={value}
                        error={
                          formState.errors?.experience?.[index]?.fromDate
                            ?.message
                        }
                        {...ref}
                        disabled={readOnly ? true : false}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={2} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Period To
                {item?.isCurrentlyWorking == false && (
                  <span className="red-asterisk"> *</span>
                )}
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`experience.${index}.toDate`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomDatePicker
                        className="custom-datepicker"
                        views="MM/YYYY"
                        name={name}
                        onChange={(e) => {
                          onChange(e);
                          if (item?.isCurrentlyWorking == false) {
                            trigger(`experience.${index}.toDate`);
                          }
                          if (
                            !_.isEmpty(watch(`experience.${index}.fromDate`))
                          ) {
                            trigger(`experience.${index}.fromDate`);
                            trigger(`experience.${index}.toDate`);
                          }
                        }}
                        value={value}
                        error={
                          formState.errors?.experience?.[index]?.toDate?.message
                        }
                        {...ref}
                        disabled={readOnly ? true : false}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={3} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Job Title
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`experience.${index}.jobTitle`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className="custom-Input"
                        ref={ref}
                        onChange={(e) => {
                          onChange(
                            e?.target?.value
                              .replace(/[^a-zA-Z\s]/g, '')
                              .trimStart(),
                          );
                        }}
                        error={
                          formState.errors?.experience?.[index]?.jobTitle
                            ?.message
                        }
                        disabled={readOnly ? true : false}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={3} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Employer/Client
                <span className="red-asterisk"> *</span>
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`experience.${index}.employerOrClient`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className="custom-Input"
                        ref={ref}
                        onChange={(e) => {
                          onChange(
                            e?.target?.value
                              .replace(/[^a-zA-Z\s]/g, '')
                              .trimStart(),
                          );
                          formControl.trigger(
                            `experience.${index}.employerOrClient`,
                          );
                        }}
                        error={
                          formState.errors?.experience?.[index]
                            ?.employerOrClient?.message
                        }
                        disabled={readOnly ? true : false}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={2} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Location
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`experience.${index}.location`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className="custom-Input"
                        ref={ref}
                        onChange={(e) => {
                          onChange(
                            e?.target?.value
                              .replace(/[^a-zA-Z\s]/g, '')
                              .trimStart(),
                          );
                        }}
                        error={
                          formState.errors?.experience?.[index]?.location
                            ?.message
                        }
                        disabled={readOnly ? true : false}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={4} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Project
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`experience.${index}.project`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className="custom-Input"
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(
                            e?.target?.value
                              .replace(/[^a-zA-Z\s]/g, '')
                              .trimStart(),
                          );
                        }}
                        error={
                          formState.errors?.experience?.[index]?.project
                            ?.message
                        }
                        disabled={readOnly ? true : false}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={fields.length === index + 1 ? 6 : 6.6} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Project Skill
              </Typography>
              <Typography
                component={'div'}
                className={
                  readOnly
                    ? 'projectskillcon projectskillconDisable'
                    : 'projectskillcon'
                }>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`experience.${index}.skillsMatched`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    if (fields.length - 1 === index) {
                      return (
                        <CustomReactTagInput
                          value={value}
                          error={
                            formState.errors?.experience?.[index]?.skillsMatched
                              ?.message
                          }
                          handleSkills={(value) =>
                            !readOnly ? handleSkills(index, value) : null
                          }
                          lastItem={true}
                          addField={addField}
                          removeField={() =>
                            !readOnly ? removeField(index) : null
                          }
                        />
                      );
                    }
                    return (
                      <CustomReactTagInput
                        value={value}
                        error={
                          formState.errors?.experience?.[index]?.skillsMatched
                            ?.message
                        }
                        handleSkills={(value) => handleSkills(index, value)}
                        addField={addField}
                        removeField={() => removeField(index)}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            {!readOnly && (
              <Grid
                item
                md={
                  fields.length === index + 1 &&
                  invalidProject &&
                  invalidProject.length
                    ? 2
                    : 1.4
                }
                sm={12}
                sx={{
                  marginLeft: 'auto',
                }}>
                <Typography
                  component={'label'}
                  className="input-title pb-2"
                  sx={{ opacity: 0 }}
                />
                <Typography
                  component={'div'}
                  className="flex-row"
                  style={{ transform: 'translateY(16px)' }}>
                  {fields.length == 1 && index <= 0 ? null : (
                    <Typography
                      component="div"
                      className="trashicon"
                      onClick={() => {
                        !readOnly && removeField(index);
                      }}>
                      <ProjectSkillsTrashSvg />
                    </Typography>
                  )}
                  {fields.length - 1 === index ? (
                    <Typography
                      component="div"
                      className="trashicon"
                      onClick={() => {
                        if (!readOnly && addField) {
                          addField ? addField() : null;
                        }
                      }}>
                      <ProjectSkillsAddSvg />
                    </Typography>
                  ) : null}

                  {invalidProject && invalidProject.length ? (
                    <img
                      src={AddIconExp}
                      style={{ cursor: 'pointer', marginBottom: '6px' }}
                      onClick={() => handleModalOpen(index)}
                    />
                  ) : null}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <Typography
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
          }}>
          <Typography
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              fontSize: '14px !important',
              fontWeight: '500 !important',
              justifyContent: 'center',
              textAlign: 'center',
            }}>
            Experience details not identified. Please click the &quot;Add&quot;
            button to include them.
          </Typography>

          <Typography
            component="div"
            className="trashicon"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              if (!readOnly && addField) {
                addField ? addField() : null;
              }
            }}>
            <ProjectSkillsAddSvg />
          </Typography>
        </Typography>
      )}
      {modalOpen && invalidProject?.length ? (
        <Modal
          open={modalOpen.show}
          onClose={handleModalClose}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
          sx={{
            borderRadius: '6px',
            outline: 'none',
            padding: 0,
          }}>
          <Box sx={style} className="resume-modal">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="d-flex-a flex-sb"
              sx={{
                backgroundColor: '#608BC1',
                height: '37px',
                width: '100%',
              }}>
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  color: '#FFFFFF',
                  fontFamily: 'Red Hat Display Medium !important',
                  fontSize: '15px !important',
                  fontWeight: '500 !important',
                  marginLeft: '20px',
                }}>
                Filtered Experience Information
              </Typography>
              <Typography
                onClick={handleModalClose}
                sx={{
                  cursor: 'pointer',
                  marginRight: '8px',
                }}>
                <img src={ExpCloseIcon} />
              </Typography>
            </Typography>

            <Box
              component={'div'}
              id="modal-modal-description"
              sx={{
                maxHeight: '85vh',
                minHeight: '85vh',
                minWidth: '60vw',
                overflow: 'auto',
                paddingBottom: '25px',
              }}>
              {invalidProject &&
              invalidProject?.length &&
              invalidProject?.filter(
                (e) => !watch('experience').some((el) => el.loopId == e.loopId),
              ).length ? (
                invalidProject
                  ?.filter(
                    (e) =>
                      !watch('experience').some((el) => el.loopId == e.loopId),
                  )
                  .map((item, index, totalArr) => (
                    <Grid
                      container
                      spacing={1}
                      key={index + 1}
                      sx={{
                        // Add styles conditionally based on the first condition
                        ...(totalArr.length === index + 1
                          ? {}
                          : {
                              borderBottom: '1px solid #608BC1',
                              marginBottom: '10px !important',
                              paddingBottom: '10px !important',
                            }),
                        // Add styles conditionally based on the second condition
                        ...{
                          marginLeft: 0,
                          marginRight: 0,
                          marginTop: 0,
                          padding: '0 10px',
                          width: '100%',
                        },
                      }}>
                      <Grid item md={2} sm={12}>
                        <Typography
                          component={'label'}
                          className="input-title-popup pb-2">
                          Period From
                          <span className="red-asterisk"> *</span>
                        </Typography>
                        <Typography component={'div'}>
                          <CustomDatePicker
                            className="custom-datepicker"
                            views="MM/YYYY"
                            name={'From Date'}
                            maxDate={new Date()}
                            value={item?.fromDate}
                            disabled={true}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={2} sm={12}>
                        <Typography
                          component={'label'}
                          className="input-title-popup pb-2">
                          Period To
                          <span className="red-asterisk"> *</span>
                        </Typography>
                        <Typography component={'div'}>
                          <CustomDatePicker
                            className="custom-datepicker"
                            views="MM/YYYY"
                            name={'periodTo'}
                            value={item?.toDate}
                            disabled={true}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={3} sm={12}>
                        <Typography
                          component={'label'}
                          className="input-title-popup pb-2">
                          Job Title
                        </Typography>
                        <Typography component={'div'}>
                          <CustomInput
                            placeHolder=""
                            name={'Job-title'}
                            value={item?.jobTitle}
                            className="custom-Input"
                            disabled={true}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={3} sm={12}>
                        <Typography
                          component={'label'}
                          className="input-title-popup pb-2">
                          Employer/Client
                          <span className="red-asterisk"> *</span>
                        </Typography>
                        <Typography component={'div'}>
                          <CustomInput
                            placeHolder=""
                            value={item?.employerOrClient}
                            name={'employee'}
                            className="custom-Input"
                            disabled={true}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={2} sm={12}>
                        <Typography
                          component={'label'}
                          className="input-title-popup pb-2">
                          Location
                        </Typography>
                        <Typography component={'div'}>
                          <CustomInput
                            placeHolder=""
                            value={item?.location}
                            name={'location'}
                            className="custom-Input"
                            disabled={true}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={4} sm={12}>
                        <Typography
                          component={'label'}
                          className="input-title-popup pb-2">
                          Project
                        </Typography>
                        <Typography component={'div'}>
                          <CustomInput
                            placeHolder=""
                            value={item?.project}
                            name={'project'}
                            className="custom-Input"
                            disabled={true}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={6.8} sm={12}>
                        <Typography
                          component={'label'}
                          className="input-title-popup pb-2">
                          Project Skill
                        </Typography>
                        <Typography
                          component={'div'}
                          className={'projectskillcon projectskillconDisable'}>
                          <CustomReactTagInput
                            value={
                              item?.skillsMatched && item?.skillsMatched.length
                                ? item?.skillsMatched
                                : []
                            }
                            lastItem={true}
                            className="popup-tags"
                          />
                        </Typography>
                      </Grid>
                      {!readOnly && (
                        <Grid item md={1.2} sm={12} sx={{ marginLeft: 'auto' }}>
                          <Typography
                            component={'label'}
                            className="input-title-popup pb-2"
                            sx={{ opacity: 0 }}
                          />
                          <Typography
                            component={'div'}
                            className="flex-row"
                            style={{
                              justifyContent: 'flex-end',
                              transform: 'translateY(16px)',
                            }}>
                            <img
                              src={addPlusIcon}
                              alt="plus"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                insert(modalOpen.currentIndex + 1, item);
                                invalidProject?.filter(
                                  (e) =>
                                    !watch('experience').some(
                                      (el) => el.loopId == e.loopId,
                                    ),
                                ).length <= 0 && handleModalClose();
                              }}
                            />
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  ))
              ) : (
                <Typography
                  sx={{
                    alignItems: 'center',
                    color: '#bf0000',
                    display: 'flex',
                    fontSize: '18px !important',
                    fontWeight: '500 !important',
                    // height: '100%',
                    justifyContent: 'center',
                    // maxHeight: '85vh',
                    minHeight: '80vh',
                    minWidth: '60vw',
                    textAlign: 'center',
                  }}>
                  No Records Found
                </Typography>
              )}
            </Box>
          </Box>
        </Modal>
      ) : (
        ''
      )}
    </Typography>
  );
};
