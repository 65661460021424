import { Card, CardContent, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ChangePassword from 'pages/common/ChangePassword';
import LogOut from 'pages/common/Logout';
import Profile from 'pages/common/Profile';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from '../PrivateStyle';

export const Myprofile: React.FC<any> = () => {
  const classes = useStyles();
  const [list, setList] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getVersionHistory();
  }, []);

  const getVersionHistory = () => {
    getAllListData(
      `${MASTER_ENDPOINT.VersionHistory}?order=desc&sortby=versionId`,
    )
      .then((resp: any) => {
        setList([resp?.data?.[0]]);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <Typography
      component={'div'}
      style={{ background: '#f5f5f5', minHeight: '100vh', paddingTop: '50px' }}>
      <Grid container>
        <Grid item md={12}>
          <Typography component={'div'} className={classes.profilecard}>
            <Typography component="h6">Personal Info</Typography>
            <Profile />
          </Typography>
          <Typography component={'div'} className={classes.profilecard}>
            <Typography component="h6">More Options</Typography>
            <Card sx={{ boxShadow: 0 }}>
              <CardContent>
                <Typography component={'div'}>
                  <ul className={classes.optiondetails}>
                    <li>
                      <ChangePassword />
                    </li>
                    <li>Settings</li>
                    <li>Privacy Policy</li>
                    <li>Terms & Conditions</li>
                    <li className={classes.logout}>
                      <LogOut />
                      <span
                        onClick={() => navigate('/version-history')}
                        style={{ color: '#292929', cursor: 'pointer' }}>
                        {list.length ? list[0].versionId : ''}
                      </span>
                    </li>
                  </ul>
                </Typography>
              </CardContent>
            </Card>
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
};

export default Myprofile;
