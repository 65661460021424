/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
// src/ModalExample.tsx
import {
  Backdrop,
  // Backdrop,
  Box,
  CircularProgress,
  Modal,
  styled,
  Typography,
  // styled,
} from '@mui/material';
import CustomSelect from 'components/CustomSelect/customSelect';
import DropDownArrowSvg from 'components/SvgAsCode/DropdownArrow';
import DummyProfileUploadResume from 'components/SvgAsCode/dummyresumephoto';
import React, { createRef, useEffect, useState } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { alertOptions } from 'utils/Config';

import DropzopneComponent from './DropZoneComponentsvg';
import SuccessUploadResume from './SuccessUploadResume';

const style = {
  bgcolor: 'background.paper',

  border: '2px solid transparent',

  boxShadow: 24,

  left: '50%',

  maxHeight: '95vh',

  // minHeight: 768,
  // minHeight: 568,
  minHeight: 600,

  overflow: 'auto',

  // Set the max height to control the scrollable area
  overflowY: 'auto',
  p: 4,
  padding: '10px',

  position: 'absolute',
  top: '50%',

  transform: 'translate(-50%, -50%)',

  width: 544,
};
// Custom styled Backdrop
const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0)',
}));
const menuStyle = {
  PaperProps: {
    sx: {
      // Margin top for spacing between select and dropdown
      border: '2px solid #BF0000',
      // Change the border color here
      borderRadius: '4px',
      // maxHeight: '522px',
      maxHeight: '322px',
      mt: '6px',
    },
  },
};
const extraSx = {
  '& .Mui-selected': {
    backgroundColor: '#eed8d9 !important',
  },

  '& .MuiInputBase-root': {
    background: '#f3f3f3',
    display: 'flex',
    height: '46px !important',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'blue',

    borderWidth: '2px', // Border color for the select box
  },

  '& .MuiSelect-icon': {
    color: '#CD1717', // Color of the dropdown icon
  },
  // Custom styles for the select box
  '& .MuiSelect-select': {
    backgroundColor: '#FFFFFF',
    border: '2px solid #BF0000',
    borderRadius: '4px',
    padding: '8px',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'darkblue', // Border color on hover
  },
  textTransform: 'none',
};

export default function UploadResumeModal({
  open,
  ClickFn,
  headerShown,
  SubmitFn,
  Success,
  Loader,
  JobListingData,
  JobTitle,
  ResumeId,
  Customer,
  ReadyMadeSourceId,
  placeHolder,
}: {
  open: boolean;
  ClickFn: any;
  headerShown?: boolean;
  SubmitFn?: any;
  ResumeId?: any;
  Success?: boolean;
  Loader?: boolean;
  JobListingData?: any;
  ReadyMadeSourceId?: any;
  JobTitle?: string;
  Customer?: string;
  placeHolder?: string;
}) {
  const dropzoneRef: any = createRef();
  const [Files, setFiles] = useState<any>(null);
  const [error, seterror] = useState<any>(undefined);
  const [SuccessFinish, setSuccessFinish] = useState<boolean>(false);
  const [acceptableStats, setacceptableStats] = useState<boolean>(false);
  const [opn, setOpn] = useState(false);
  const [value, setvalue] = useState(undefined);
  const [workStatusList, setworkStatusList] = useState([
    // { label: 'Select a source for the resume', value: undefined },
  ]);
  const accept = {
    'application/msword': ['.doc', '.docx'],
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx',
    ],
  };

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: accept,
    maxFiles: 1,
    maxSize: 5242880,
    noKeyboard: true,

    onDrop: () => {
      setacceptableStats(false);
    },
    onDropRejected(fileRejections) {
      const FilReject: any =
        fileRejections &&
        fileRejections.length > 0 &&
        fileRejections[0].errors &&
        fileRejections[0].errors.length > 0 &&
        fileRejections[0].errors[0] &&
        fileRejections[0].errors[0].code
          ? fileRejections[0].errors[0].code
          : undefined;

      if (FilReject && FilReject === 'file-too-large') {
        Files && Files.length > 0
          ? toast.error('File too large. Max size is 5 MB.', alertOptions)
          : seterror('File too large. Max size is 5 MB.');
      }
      if (FilReject && FilReject === 'file-invalid-type') {
        Files && Files.length > 0
          ? toast.error(
              'Unsupported file format. Please adjust and try again.',
              alertOptions,
            )
          : seterror('Unsupported file format. Please adjust and try again.');
      }
    },
  });

  const fileTypeMapping: { [mimeType: string]: string[] } = accept;

  // Validate file based on MIME type and extension
  const validateFile = (file: File) => {
    const allowedExtensions = fileTypeMapping[file.type] || [];
    const fileExtension = file.name
      .slice(((file.name.lastIndexOf('.') - 1) >>> 0) + 2)
      .toLowerCase();
    return allowedExtensions.includes(`.${fileExtension}`);
  };
  useEffect(() => {
    if (JobListingData) {
      const JobListingDatas = JobListingData.map((ty: any) => ({
        label: ty.name,
        value: ty.id,
      }));
      setworkStatusList([
        { label: 'Select a source of the resume', value: undefined },
        ...JobListingDatas,
      ]);
    }
  }, [JobListingData]);
  useEffect(() => {
    if (Success) {
      setSuccessFinish(true);
    } else {
      setSuccessFinish(false);
    }
  }, [Success]);
  useEffect(() => {
    setOpn(open);
  }, [open]);
  useEffect(() => {
    if (ReadyMadeSourceId) {
      setvalue(ReadyMadeSourceId);
    }
  }, [ReadyMadeSourceId]);
  useEffect(() => {
    if (acceptedFiles.length > 0) {
      const newFile = acceptedFiles[0];

      if (validateFile(newFile)) {
        setFiles(acceptedFiles ? acceptedFiles : []); // Replace old file with new file
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptableStats, error, acceptedFiles]);

  const filterEdit = (key: any) => {
    setvalue(key);
  };
  function handleClose() {
    seterror(undefined);
    setSuccessFinish(!true);
    setFiles(null);
    setvalue(undefined);
    ClickFn();
  }

  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    seterror(undefined);
    if (dropzoneRef.current) {
      // dropzoneRef.current.open();
      setacceptableStats(false);
      dropzoneRef.current.click();
    }
  };

  function handleFinish() {
    ResumeId ? SubmitFn(value, Files, ResumeId) : SubmitFn(value, Files);
  }
  const ValidChecker: any =
    value && Files && Files.length > 0 && !Loader
      ? { cursor: 'pointer' }
      : {
          backgroundColor: '#BDBDBD',
          cursor: 'not-allowed',
        };
  return (
    <div>
      <Modal
        open={opn}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        BackdropComponent={CustomBackdrop}>
        <>
          {Loader && (
            <Typography
              component={'div'}
              sx={{
                // backdropFilter: 'blur(3.5px) !important',
                left: ' 50%',
                position: 'absolute',
                top: ' 50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1500,
              }}>
              <CircularProgress sx={{ color: '#bf0000' }} size={40} />
            </Typography>
          )}
          <div className="modalBackdrop" onClick={handleClose} />
          <Box
            sx={style}
            className="UploadResumeinnerbox"
            style={Loader ? { cursor: 'none' } : {}}>
            {Loader && (
              <div
                style={{
                  backgroundColor: 'transparent',
                  bottom: '0px',
                  left: '0px',
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                  zIndex: 2,
                }}
              />
            )}
            <header>
              <div className="heading">Upload Resume</div>
              <div
                className="closebtn"
                onClick={handleClose}
                style={{ zIndex: 3 }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18">
                  <path
                    fill="#000"
                    d="M17.813.189a.645.645 0 00-.91 0l-7.9 7.9-7.905-7.9a.643.643 0 00-.91.91l7.905 7.9-7.901 7.903a.643.643 0 10.91.91l7.9-7.904 7.905 7.9a.643.643 0 00.906-.906L9.912 8.998l7.901-7.903a.643.643 0 000-.906z"
                  />
                </svg>
              </div>
            </header>
            <div
              className="customertitle"
              style={
                headerShown
                  ? {
                      marginBottom: '15.5px',
                      paddingBottom: '18px',
                    }
                  : {
                      marginBottom: '15.5px',
                      paddingBottom: '13.5px',
                    }
              }>
              {headerShown && (
                <>
                  <div className="company">
                    <span className="title">Customer</span>
                    <span className="value">{Customer ? Customer : ''}</span>
                  </div>
                  <div className="company">
                    <span className="title">Job Title</span>
                    <span className="value">{JobTitle ? JobTitle : ''}</span>
                  </div>
                </>
              )}
            </div>
            <main>
              {SuccessFinish ? (
                <>
                  <div
                    className="UploadResumeSuccess"
                    style={{ marginTop: '39.7px' }}>
                    <div className="resumesvg">
                      <SuccessUploadResume />
                    </div>
                    <div className="successTitle">
                      Resume successfully uploaded
                    </div>
                    <div className="successDescription">
                      The resume is being processed. It might take about 10
                      minutes for the process to complete. Please check the
                      Resume Validation page after 10 minutes.
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="dropdowntitle">
                    Source From <span style={{ color: '#000000' }}>*</span>
                  </div>
                  <div className="dropdownbox">
                    <CustomSelect
                      onChange={(e: any) => {
                        filterEdit(e?.target?.value);
                        // setJobFilterState({
                        //   ...jobFilter,
                        //   ['workStatus']: e?.target?.value,
                        // });
                      }}
                      menuStyle={menuStyle}
                      extraSx={extraSx}
                      value={value}
                      labelKey={'label'}
                      valueKey={'value'}
                      options={workStatusList}
                      placeHolder={
                        placeHolder
                          ? placeHolder
                          : 'Select a source for the resume'
                      }
                      className={'common-select'}
                      menuItemSx={{
                        // backgroundColor: '#eed8d9 !important',
                        marginTop: '8px',
                      }}
                      IconComponent={DropDownArrowSvg}
                      UploadResume
                    />
                  </div>
                  <div
                    className="Dropzone"
                    style={
                      error && error.length > 0 && !Files
                        ? { borderColor: '#ff0000', position: 'relative' }
                        : { position: 'relative' }
                    }>
                    <Dropzone noClick noKeyboard>
                      {() => {
                        // let acceptedFile = acceptedFiles;
                        return (
                          <div className="container">
                            <div
                              {...getRootProps({ className: 'dropzone' })}
                              className="dropsvg"
                              style={{
                                bottom: '0px',
                                left: '0px',
                                position: 'absolute',
                                right: '0px',
                                top: '0px',
                              }}>
                              <input
                                {...getInputProps()}
                                style={{ height: 0 }}
                              />
                            </div>
                            <div
                              className="dropzonesvg"
                              ref={dropzoneRef}
                              style={{
                                ...(Files && Files.length > 0
                                  ? { display: 'none' }
                                  : {}),
                              }}>
                              <DropzopneComponent baseColor={'#BDBDBD'} />
                            </div>
                            <div className="footer">
                              {/* <div className="txt">
                          JPG, PNG or PDF, file size no more than 10MB
                          </div> */}
                            </div>

                            {Files && Files.length > 0 ? (
                              <>
                                {/* <div style={{ height: '30px' }} /> */}

                                <div className={'uploadedContainer'}>
                                  {/* <div className="uploadedTitle">
                                Uploaded Files
                              </div> */}
                                  <div className="profile">
                                    <DummyProfileUploadResume />
                                  </div>
                                  <ul>
                                    {Files.map((file: any) => (
                                      <li key={file.path}>
                                        <span
                                          title={
                                            file &&
                                            file.path &&
                                            file.path.length > 0
                                              ? file.path
                                              : ''
                                          }>
                                          {file &&
                                          file.path &&
                                          file.path.length > 18
                                            ? `${`${file.path.slice(
                                                0,
                                                9,
                                              )}....${file.path.slice(
                                                file.path.length - 8,
                                                file.path.length,
                                              )}`}`
                                            : file &&
                                              file.path &&
                                              file.path.length > 0
                                            ? file.path
                                            : ''}{' '}
                                          {/* - {file.size} bytes */}
                                        </span>
                                        {/* <span
                                      className="remove removeFiles"
                                      onClick={() => handleRemovefiles(file)}>
                                      x
                                    </span> */}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                                <div className="btn-con">
                                  <div
                                    onClick={openDialog}
                                    className="Upload-button">
                                    <span className="txt">Change File</span>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="btn-con">
                                <div className="or">
                                  Select a file or drag and drop here
                                </div>
                                <div className="or-below-text">
                                  Choose a DOC, DOCX, or PDF file smaller than
                                  5MB.
                                </div>
                                {error && error.length > 0 && (
                                  <div
                                    className="or-below-text"
                                    style={{ color: '#BF0000' }}>
                                    {error}
                                  </div>
                                )}
                                <div
                                  onClick={openDialog}
                                  className="Upload-button">
                                  <span className="txt">Select file</span>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Dropzone>
                  </div>
                </>
              )}
            </main>
            <footer
              style={{
                ...(SuccessFinish
                  ? {
                      justifyContent: 'center',
                      marginBottom: '20px',
                      marginRight: '0px',
                    }
                  : {}),
              }}>
              {Files && Files.length > 0 && !SuccessFinish ? (
                <div
                  className="button buttonUp"
                  style={ValidChecker}
                  onClick={
                    value && Files && Files.length > 0 && !Loader
                      ? handleFinish
                      : () => undefined
                  }>
                  Upload
                </div>
              ) : SuccessFinish ? (
                <div
                  className="button"
                  style={{ cursor: 'pointer' }}
                  onClick={
                    value && Files && Files.length > 0 && !Loader
                      ? handleClose
                      : () => undefined
                  }>
                  Done
                </div>
              ) : (
                <div
                  className="button buttonUp"
                  style={ValidChecker}
                  onClick={
                    value && Files && Files.length > 0 && !Loader
                      ? handleFinish
                      : () => undefined
                  }>
                  Upload
                </div>
              )}
            </footer>
          </Box>
        </>
      </Modal>
    </div>
  );
}
