export const Formatify = (str: string) =>
  str.split(' ').join('').replaceAll(/\s/g, '').toLowerCase();

export const invalidNo = [
  '1000000000',
  '10000000000',
  '3000000000',
  '30000000000',
  '4000000000',
  '40000000000',
  '5000000000',
  '30000000000',
  '6000000000',
  '60000000000',
  '7000000000',
  '70000000000',
  '8000000000',
  '80000000000',
  '9000000000',
  '90000000000',
];
