import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { Tag } from 'react-tag-input/types/components/SingleTag';

export const CustomReactTagInput: React.FC<any> = (props) => {
  const { value, error, handleSkills, className = '' } = props;
  const [tags, setTags] = useState<Array<Tag>>([]);

  const handleDelete = (index: number) => {
    setTags(tags.filter((_, i) => i !== index));
    handleSkills
      ? handleSkills(
          tags.filter((_, i) => i !== index).map((item) => item.text),
        )
      : null;
  };
  useEffect(() => {
    if (value && value.length) {
      setTags(
        value.map((datas) => {
          return {
            className: '',
            id: datas,
            text: datas,
          };
        }),
      );
    } else {
      setTags([]);
    }
  }, [value]);

  const onClearAll = () => {
    setTags([]);
  };
  return (
    <Typography component={'div'} className={'TagsInputStyleCon'}>
      <Typography
        component={'div'}
        className={`TagsInputStyleConInner ${
          tags && tags.length
            ? tags.length < 3
              ? 'nonTagWidth'
              : ''
            : 'nonTagWidth'
        }`}>
        <Typography
          component={'div'}
          className={`${'TagsInputStyle'} ${String(className)}`}>
          {tags ? (
            <ReactTags
              tags={tags}
              inputFieldPosition="top"
              handleDelete={handleDelete}
              clearAll
              onClearAll={onClearAll}
              allowUnique={false}
              allowAdditionFromPaste
            />
          ) : null}
        </Typography>
        {error ? <Typography className="tagserror">{error}</Typography> : null}
      </Typography>
    </Typography>
  );
};
