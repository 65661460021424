import { Typography } from '@mui/material';
import { CustomPDFViewer } from 'components/CustomPDFViewer';
import React from 'react';

type Props = {
  url: string;
  hideDownload?: boolean;
  scale?: number;
  fileName?: string;
  from?: string;
  analyticalCandidateName?: string;
  candidateId?: string;
};
export const PdfViewer: React.FC<Props> = (props: Props) => {
  const {
    analyticalCandidateName,
    hideDownload,
    candidateId,
    from,
    fileName,
    url,
  } = props;

  return (
    <Typography component={'div'}>
      <div className="pdf-app-views">
        <div className="pdf-page-container">
          <CustomPDFViewer
            candidateId={candidateId}
            fileUrl={url}
            fileName={fileName}
            from={from}
            analyticalCandidateName={analyticalCandidateName}
            hideDownload={hideDownload}
          />
        </div>
      </div>
    </Typography>
  );
};
