import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  ExperienceInfo as ExperienceInfoResponse,
  GeneralDetails,
} from 'types';

import ExpInfo from '../../../../assets/images/expInfo.svg';
import { EducationInfo } from './Information/EducationInfo';
import { ExperienceInfo } from './Information/ExperienceInfo';
import { ProfileInfo } from './Information/ProfileInfo';
import { SkillsInfo } from './Information/Skills';

type Props = {
  header: string;
  type: string;
  control: UseFormReturn<GeneralDetails>;
  closeAcc?: boolean;
  readOnly?: boolean;
  isFocused?: boolean;
  setIsFocused: (src: boolean) => void;
  show?: string;
  setShow?: (src: string) => void;
  linkedInVerification?: (src: boolean) => void;
  invalidProject?: ExperienceInfoResponse[];
  validProject?: ExperienceInfoResponse[];
  handleModalClose?: any;
  handleModalOpen?: any;
};

export const AccordionInfo: React.FC<{
  type: string;
  control: UseFormReturn<GeneralDetails>;
  readOnly?: boolean;
  isFocused?: boolean;
  setIsFocused: (src: boolean) => void;
  show?: string;
  setShow?: (src: string) => void;
  linkedInVerification?: (src: boolean) => void;
  invalidProject?: ExperienceInfoResponse[];
  validProject?: ExperienceInfoResponse[];
}> = ({
  type,
  control,
  readOnly,
  isFocused,
  setIsFocused,
  show,
  setShow,
  linkedInVerification,
  invalidProject,
  validProject,
}) => {
  switch (type) {
    case 'profileInfo':
      return (
        <ProfileInfo
          control={control}
          readOnly={readOnly}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          show={show}
          setShow={setShow}
          linkedInVerification={linkedInVerification}
        />
      );
    case 'experienceInfo':
      return (
        <ExperienceInfo
          control={control}
          readOnly={readOnly}
          invalidProject={invalidProject}
          validProject={validProject}
        />
      );
    case 'educationInfo':
      return <EducationInfo control={control} readOnly={readOnly} />;
    case 'skils':
      return <SkillsInfo control={control} readOnly={readOnly} />;
    default:
      return null;
  }
};
export const AccordionView: React.FC<Props> = (props: Props) => {
  const {
    header,
    type,
    control,
    closeAcc,
    readOnly,
    isFocused,
    setIsFocused,
    show,
    setShow,
    linkedInVerification,
    validProject,
    invalidProject,
  } = props;
  const [expanded, setExpanded] = React.useState(true); // Initially expanded

  const handleChange = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (closeAcc) {
      setExpanded(false);
    }
  }, [closeAcc]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange}
      className="accordion-root">
      <AccordionSummary
        expandIcon={
          expanded ? (
            <RemoveIcon className="icon-color" />
          ) : (
            <AddIcon className="icon-color" />
          )
        }
        aria-controls="panel1-content"
        id="panel1-header">
        <Typography
          component={'div'}
          className="accordion-heading"
          sx={{
            width: '100%',
          }}>
          {header === 'Experience Information' ? (
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
              }}>
              {header}
              <Tooltip
                title={
                  'This count shows the total projects parsed by the resume parser and those displayed in the UI based on filters. Click the project icon in each row to add filtered projects to the main list.'
                }
                placement="bottom-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: '#608BC1',
                      borderRadius: '4px',
                      color: '#FFFFFF',
                      fontFamily: 'Red Hat Text',
                      fontSize: '12px',
                      fontWeight: 400,
                    },
                  },
                }}>
                <Typography
                  component="div"
                  sx={{
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '4px',
                    display: 'flex',
                    gap: 0.5,
                    paddingLeft: '7px',
                    textTransform: 'none',
                  }}>
                  <img src={ExpInfo} />
                  <span
                    style={{
                      color: '#608BC1',
                      display: 'flex',
                      fontSize: '12px',
                      fontWeight: 700,
                      paddingRight: '8px',
                    }}>
                    Featured Projects{' '}
                  </span>
                  <Typography
                    sx={{
                      backgroundColor: '#608BC1',
                      borderRadius: '4px',
                      color: '#FFFFFF',
                      fontSize: '12px',
                      fontWeight: '700 !important',
                      padding: '4px 6px',
                    }}>
                    {control.watch('experience') &&
                    control.watch('experience').length
                      ? control.watch('experience').length
                      : 0}{' '}
                    /{' '}
                    {(control.watch('experience') &&
                    control.watch('experience').length
                      ? control.watch('experience').length
                      : 0) +
                      (invalidProject && invalidProject.length
                        ? invalidProject?.filter(
                            (e) =>
                              !control
                                .watch('experience')
                                .some((el) => el.loopId == e.loopId),
                          ).length
                        : 0)}
                  </Typography>
                </Typography>
              </Tooltip>
            </Box>
          ) : (
            header
          )}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionInfo
          type={type}
          control={control}
          readOnly={readOnly}
          isFocused={isFocused}
          setIsFocused={setIsFocused}
          show={show}
          setShow={setShow}
          linkedInVerification={linkedInVerification}
          invalidProject={invalidProject}
          validProject={validProject}
        />
      </AccordionDetails>
    </Accordion>
  );
};
