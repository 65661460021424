import { Typography } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import { alpha, styled } from '@mui/material/styles';
import search from 'assets/images/search.svg';
import React, { ReactElement, useState } from 'react';

const Search = styled('div')(({ theme }) => ({
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  marginLeft: 0,
  marginRight: theme.spacing(2),
  position: 'relative',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  marginTop: '22px',
  padding: theme.spacing(0, 2),
  pointerEvents: 'none',
  position: 'unset',
  zIndex: '1',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
  color: 'inherit',
}));

type InputProps = {
  onChange?: (src: any) => void;
  value?: string;
  placeHolderText?: string;
  staticWidth?: boolean;
};

const ResumeValSearchFilter = React.forwardRef(
  (props: InputProps): ReactElement => {
    const [searchVal, setSearchVal] = useState<string>('');
    const { onChange, placeHolderText, staticWidth } = props;

    return (
      <Typography
        className={
          staticWidth ? 'resume-filter-search-w' : 'custom-resume-searchbar'
        }>
        <Search>
          <StyledInputBase
            // onChange={(e) => {
            //   setSearchVal(e.target.value);
            //   onChange && onChange(e);
            // }}
            onChange={(e) => {
              if (
                e.target.value.startsWith(' ')
                // ||
                // /[^a-zA-Z0-9\s]/.test(e.target.value) ||
                // /\d/.test(e.target.value)
              ) {
                return;
              } else {
                setSearchVal(e.target.value.trimStart());
                onChange && onChange(e);
              }
            }}
            placeholder={placeHolderText ? placeHolderText : 'Uploaded by'}
            value={searchVal}
            inputProps={{
              'aria-label': 'search',
              type: 'search',
            }}
          />
          <SearchIconWrapper>
            <img src={search} style={{ width: '16px' }} />
          </SearchIconWrapper>
        </Search>
      </Typography>
    );
  },
);

export default ResumeValSearchFilter;
